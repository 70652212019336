import React, { useEffect } from "react";
import ProfileMenu from "../../dashcomponents/profilemenu/ProfileMenu";
import styles from "./myaddress.module.css";
import { useNavigate } from "react-router-dom";
import {
  getAllAddress,
  deleteAddress,
} from "../../../redux/reducers/addressSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";
import ErrorPage from "../../../components/errorpage/ErrorPage";

const MyAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addressData, isLoading } = useSelector((state) => state.address);

  useEffect(() => {
    dispatch(getAllAddress());
  }, [dispatch]);

  const handleDelete = (addressId) => {
    console.log("Deleting address with ID:", addressId);
    dispatch(deleteAddress(addressId));
  };

  return (
    <div style={{display:"flex"}}>
    <ProfileMenu selected={"My Address"}/>
    <div className={styles.myprofile}>
      <div className={styles.prodetailsContainer}>
        <div className={styles.profileheader}>
          <h2 className={styles.pheading}>My Address</h2>
          <button
            className={styles.editButton}
            onClick={()=> navigate("/addaddress")}
          >
     +<span className={styles.plus}> Add New Address</span>
          </button>
        </div>

        {/* Conditional rendering based on loading, data availability, and error state */}
        {isLoading ? (
              <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              width="100vh"
            >
              <CircularProgress />
            </Box>
        ) : addressData && addressData.length > 0 ? (
          <div className={styles.gridContainer}>
            {addressData.map((address) => (
              <div key={address._id} className={styles.card}>
                <div className={styles.header}>
                  <span>SHIPPING ADDRESS</span>
                  {address.isDefault && (
                    <span className={styles.default}>DEFAULT</span>
                  )}
                </div>

                <div className={styles.content}>
                  <p className={styles.name}>
                    {address.firstName} {address.lastName}
                  </p>
                  <p className={styles.address}>
                    {address.streetAddress}, {address.city}, {address.state},{" "}
                    {address.country}, {address.zip}
                  </p>
                  <p className={styles.phone}>
                    Phone number: <strong>{address.phone}</strong>
                  </p>
                </div>

                <div className={styles.orderbuttons}>
                  <button
                    className={styles.trackOrderBtn}
                    onClick={() =>
                      navigate("/addaddress", { state: { addressData } })
                    }
                  >
                    Edit
                  </button>
                  <button
                    className={styles.trackOrderBtn}
                    onClick={() => handleDelete(address._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
        <div className={styles.nodata}>
       <p className={styles.noadd}>No address added yet!</p>
        </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default MyAddress;
