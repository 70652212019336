import React from 'react'
import styles from "./errorpage.module.css";
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({content}) => {
    const nav = useNavigate()
    return (


        <div className={styles.contactCol2}>
            <img src='/assets/images/error.png' />

            {content ? (<h2>{content}</h2>) : (<h2>Oops! Products not found</h2>)}
            
            <div className={styles.buttonGroup}>
                <button type="submit" className={styles.saveButton} onClick={() => nav("/")}>
                    Back To Home
                </button>
            </div>

        </div>
    )
}

export default ErrorPage