import React from 'react'
import styles from "./earthFriendly.module.css"
const EarthFriendly = () => {
  return (
    <div className={styles.container}>
          <div className={styles.section}>
          <div className={styles.box1}>
          <img src={require('../../assets/images/image43.png')} className={styles.mainImg_1} />
          <h2 className={styles.heading}>Soft as a Cloud</h2>
          <p className={styles.head}>The next best thing to snuggling skin-to-skin</p>
          </div>
          <div className={styles.box1}>
          <img src={require('../../assets/images/image44.png')} className={styles.mainImg_1} />
          <h2 className={styles.heading}>Earth friendly</h2>
          <p className={styles.head}>GOTS certified, organic cotton. None of the bad stuff.</p>
          </div>
          <div className={styles.box1}>
          <img src={require('../../assets/images/image45.png')} className={styles.mainImg_1} />
          <h2 className={styles.heading}>Made for Real Life</h2>
          <p className={styles.head}>Wash-tested fabrics that never shrink, pill, or fade.</p>
          </div>



          </div>
        
    </div>
  )
}

export default EarthFriendly