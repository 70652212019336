import React from 'react';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./addcard.module.css"
import CardBody from '../../dashcomponents/cardbody/CardBody';
 
const AddCard = () => {
    return (
        <>
       <div className={styles.myprofile}>
            <div className={styles.promenu}>
            <ProfileMenu />
            </div>
            <CardBody />
       </div>
          
      
        </>
    );
};

export default AddCard;
