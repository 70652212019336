import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./productpage.module.css";
import Slider from "react-slick";
import StarRatings from "react-star-ratings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import {CircularProgress} from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import moment from "moment";
import {
  Card,
  Carticon,
  Color1,
  HomeNext,
  HomeNexts,
  HomePrev,
  Likewhite,
  Shifticon,
  Shourticon,
  Star,
  Star12,
  Troukicon,
} from "../../Svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAProductById,
  getSimilarProducts,
  addViewCount,
  getAllReviews,
  subscribeToOutofstockProducts,
} from "../../redux/reducers/productSlice";
import { addProductToCart } from "../../redux/reducers/cartSlice";
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
} from "../../redux/reducers/wishlistSlice";

const Productpage = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const [img, setImg] = useState(null);
  const token = localStorage.getItem("token");

  const { id } = useParams();

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(getAProductById(id)),
            dispatch(getAllReviews(id)),
            dispatch(getSimilarProducts(id)),
            dispatch(addViewCount(id)),
          ]);
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };

      fetchData();
    }
  }, [id, dispatch]);

  const { singleProduct, similarProducts, averageStar, reviews , loading } = useSelector(
    (state) => state.products
  );

  const { wishlistData } = useSelector((state) => state.wishlist);

  const { cartData } = useSelector((state) => state.cart);

  const formattedDate = (date) => {
    const dateFromMongoDB = moment(date);

    // If the date is within the last hour, show "xx mins ago"
    if (moment().diff(dateFromMongoDB, "minutes") < 60) {
      return dateFromMongoDB.fromNow(); // e.g., "45 minutes ago"
    }

    // If the date is today, show "Today at hh:mm A"
    if (moment().isSame(dateFromMongoDB, "day")) {
      return `Today at ${dateFromMongoDB.format("hh:mm A")}`;
    }

    // If the date is yesterday, show "Yesterday at hh:mm A"
    if (moment().subtract(1, "days").isSame(dateFromMongoDB, "day")) {
      return `Yesterday at ${dateFromMongoDB.format("hh:mm A")}`;
    }

    // If the date is older, show "MM-DD-YYYY hh:mm A"
    return dateFromMongoDB.format("DD-MM-YYYY hh:mm A");
  };

  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);

  console.log("singleProduct", singleProduct);

  console.log("similar products", similarProducts);

  const sliderSettings = {
    dots: false,
    infinite: similarProducts.length > 1,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1038,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings = {
    dots: true,
    infinite:  false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "60px",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "40px"
        },
      },
      {
        breakpoint: 395,
        settings: {
          centerPadding: "0px",
          centerMode: false,  
          
        }
      }
    ]
  };

  // console.log("Slider settings:", sliderSettings);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenr, setIsOpenR] = useState(true);
  const [isOpenf, setIsOpenF] = useState(true);
  const [count, setCount] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  const [isActive, setIsActive] = useState(false);



  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    if (count === null) {
      setIsActive(false);
    }
  };

  // console.log("first,", currentImages);

  // console.log("selectedSize", selectedSize);
  // console.log("selectedColor", selectedColor);
  // console.log("count", count);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdownR = () => {
    setIsOpenR(!isOpenr);
  };
  const toggleDropdownF = () => {
    setIsOpenF(!isOpenf);
  };

  const handleAddToCart = (item) => {
    const body = {
      productId: item?._id,
      quantity: 1,
      countInc: true,
      size: selectedSize || "S",
      color: selectedColor || "Red",
    };

    dispatch(addProductToCart(body));
  };

  const handleAddToWishList = (item) => {
    // console.log("itemmmmmmmmm", item);

    const body = {
      productId: item._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    const bodyToDelete = {
      item: item,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    if (
      wishlistData?.some((wishlistItem) => wishlistItem?.item?._id === item._id)
    ) {
      dispatch(deleteFromWishlist(bodyToDelete));
    } else {
      dispatch(addToWishlist(body));
    }
  };

  const additionalInfo = [
    { icon: <Card />, text: "Secure payment" },
    { icon: <Shourticon />, text: "Size & Fit" },
    { icon: <Troukicon />, text: "Free shipping" },
    { icon: <Shifticon />, text: "Free Shipping & Returns" },
  ];

  // Array of product description lines
  const productDescriptionArray = [
    "100% Bio-washed Cotton – makes the fabric extra soft & silky. Flexible ribbed crew neck Precisely stitched with no pilling & no fading. Provide all-time comfort. Anytime, anywhere.Infinite range of matte-finish HD prints.",
  ];

  const validVariation = singleProduct?.variationInventory?.find(
    (variation) =>
      variation.color === selectedColor && variation.size === selectedSize
  );

  const stockQuantity = singleProduct?.stockQuantity || 0;
  const isOutOfStock = stockQuantity === 0;
  const isLowStock = stockQuantity > 0 && stockQuantity < 5;

  const addToCart = (item) => {
    if (!token) {
      nav("/login");
      toast.warning("Please login");
      return;
    }
    if (validVariation && !isOutOfStock) {
      const body = {
        productId: item?._id,
        count: count || 1,
        countInc: true,
        size: selectedSize,
        color: selectedColor,
      };
      console.log("Adding to cart", count);
      dispatch(addProductToCart(body));
      setCount(1);
    } else {
      // alert("Selected color and size combination is not available or out of stock.");
    }
  };

  useEffect(() => {
    if (singleProduct?.variations?.length) {
      const initialColor = singleProduct?.variations.filter(
        (variation) => variation?.type?.name === "Color"
      )[0]?.value[0];
      const initialSize = singleProduct?.variations.filter(
        (variation) => variation?.type?.name === "Size"
      )[0]?.value[0];
      setSelectedColor(initialColor || null);
      setSelectedSize(initialSize || null);
      setImg(singleProduct?.featuredImage);
    }
  }, [singleProduct]);

  useEffect(() => {
    if (
      singleProduct?.variationInventory?.length &&
      selectedColor &&
      selectedSize
    ) {
      if (validVariation) {
        setCurrentImages(validVariation.images || []);
        // You can also store other details of the matched variation, like price or availability
      } else {
        // If no matching variation is found, you can clear or show a warning
        setCurrentImages([]);
      }
    }
  }, [selectedColor, selectedSize, singleProduct]);

  const totalReviews = reviews?.ratingCounts?.reduce(
    (sum, item) => sum + item.count,
    0
  );

  const filteredProductDetails = singleProduct?.variations?.filter(
    ({ name }) => name !== "Size" && name !== "Color"
  );

  const defaultProductDetails = [
    { name: "Fabric" },
    { name: "Pattern" },
    { name: "Fit" },
    { name: "Neck" },
    { name: "Sleeve" },
    { name: "Style" },
  ];

  // Map over default labels and add values or "N/A" if not found
  const productDetails = defaultProductDetails?.map((defaultDetail) => {
    const matchingVariation = filteredProductDetails?.find(
      (variation) => variation?.name === defaultDetail?.name
    );
    return {
      name: defaultDetail?.name,
      value: matchingVariation ? matchingVariation?.value : "N/A",
    };
  });

  return (
    <>
    {loading ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 20,
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
    <div className={styles.containes}>
      <div className={styles.container}>
        <div className={styles.section1}>
          <div className={`${styles.slidercontant} slidercontant`}>
            <Slider {...settings} ref={sliderRef2}>
              {/* First slide with specific image */}
              <div className={styles.card1}>
              {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 20,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
                <div className={styles.card2}>
                  <img
                    key={1}
                    src={img}
                    loading="lazy"
                    className={styles.mainImg}
                    alt={`Item `}
                  />
                </div>
                 )}
              </div>
       

              {/* Remaining slides with currentImages array */}
              {currentImages?.slice(1).map((image, index) => (
                <div key={index + 1} className={styles.card1} >
                  <div className={styles.card2}>
                    <img
                      src={image}
                      onClick={() => setImg(image)}
                      className={styles.mainImg}
                      alt={`Item ${index + 2}`}
                    />
                  </div>
                  {/* Custom Navigation Buttons */}
                </div>
              ))}
            </Slider>
          </div>
          <div className={styles.navbutton1}>
            <div
              className={styles.prev}
              style={{cursor:"pointer"}}
              onClick={() => sliderRef2.current?.slickPrev()}
            >
              <HomePrev />
            </div>
            <div
              className={styles.next}
              style={{cursor:"pointer"}}
              onClick={() => sliderRef2.current?.slickNext()}
            >
              <HomeNexts />
            </div>
          </div>
        </div>

        <div className={styles.section2}>
          <div>
            <h5 className={styles.heading}>{singleProduct?.name}</h5>

            {/* Rating and Price Section */}
            <div className={styles.ratingPrice}>
              <div className={styles.rating}>
                <span className={styles.star} style={{ fontSize: "34px" }}>
                  <StarRatings
                    rating={Number(averageStar) || 0}
                    starRatedColor="gold"
                    numberOfStars={5}
                    starDimension="25px"
                    starSpacing="5px"
                    name="rating"
                  />
                </span>
                <span className={styles.num}>{averageStar}</span>
              </div>
              <div className={styles.price}>
                <span className={styles.originalPrice}>
                  {currency === "USD" ? "$" : "₹"}
                  {currency === "USD"
                    ? (singleProduct?.basePrice * rate).toFixed(2)
                    : singleProduct?.basePrice}
                </span>
                <span className={styles.discountedPrice}>
                  {currency === "USD" ? "$" : "₹"}
                  {currency === "USD"
                    ? (singleProduct?.salePrice * rate).toFixed(2)
                    : singleProduct?.salePrice}
                </span>
              </div>
            </div>
            {isLowStock && (
              <div className={styles.lowStock}>
                Only {stockQuantity} left in stock!
              </div>
            )}
            {isOutOfStock && (
              <div className={styles.outOfStock}>Out of stock</div>
            )}
          </div>
          <p className={styles.ptext}>Select Size</p>
          <div className={styles.sizeOptions}>
            {singleProduct?.variations
              ?.filter((variation) => variation?.type?.name === "Size")
              ?.flatMap((sizeVariation) =>
                sizeVariation?.value?.map((size, index) => (
                  <button
                    key={index}
                    className={`${styles.sizeButton} ${
                      selectedSize === size ? styles.activeSize : ""
                    }`}
                    onClick={() => setSelectedSize(size)}
                  >
                    {size}
                  </button>
                ))
              )}
          </div>

          <p className={styles.ptext}>Colours Available</p>

          {/* Colors Available */}
          <div className={styles.colorsAvailable}>
            <div className={styles.colorOptions}>
              {singleProduct?.variations
                ?.filter((variation) => variation?.type?.name === "Color")
                ?.flatMap((colorVariation) =>
                  colorVariation?.value?.map((color, index) => {
                    const colorHex = chroma.valid(color)
                      ? chroma(color).hex()
                      : "#FFF";
                    return (
                      <button
                        key={index}
                        className={`${styles.colorCircle} ${
                          selectedColor === color ? styles.activeColor : ""
                        }`}
                        style={{
                          backgroundColor: colorHex,
                          border:
                            selectedColor === color
                              ? "2px solid black"
                              : "none",
                        }}
                        onClick={() => setSelectedColor(color)} // Update selected color
                      />
                    );
                  })
                )}
            </div>

            {!validVariation ? (
              <div className={styles.unavailable}>currently unavailable</div>
            ) : null}

            
          </div>

          {/* Cart Button Section */}
          {/* <div className={styles.cartbutton}>
            <p className={styles.g_text}>QTY</p>
            <input
              type="number"
              className={styles.cartInput}
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
              min="1"
            />{" "}
            <button
               className={`${styles.addToCart} ${!validVariation ? styles.disabledButton : ''}`}
              onClick={() => addToCart(singleProduct)}
              disabled={!validVariation} 
            >
              <span className={styles.cartIcon}>
                <Carticon />
              </span>{" "}
              Add to cart
            </button>
          </div> */}

          <div className={styles.cartbutton}>
          {isOutOfStock ? null : (
  <>
    <p className={styles.g_text}>QTY</p>
    <div className={styles.cartInputContainer}>
      <button
        className={styles.counterButton}
        onClick={() => setCount((prev) => Math.max(1, prev - 1))}
      >
        -
      </button>
      <input
        type="number"
        placeholder="1"
        className={styles.cartInput}
        value={isActive ? "" : count}
        onChange={(e) => setCount(Math.max(1, Number(e.target.value)))}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        min={1}
        disabled
      />
      <button
        className={styles.counterButton}
        onClick={() => setCount((prev) => prev + 1)}
      >
        +
      </button>
    </div>
  </>
)}

            {isOutOfStock ? (
              <button
                className={styles.addToCart}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    subscribeToOutofstockProducts({
                      productId: singleProduct?._id,
                    })
                  );
                }}
              >
                <span className={styles.cartIcon}>{/* <Carticon /> */}</span>
                Notify me
              </button>
            ) : (
              <button
                className={styles.addToCart}
                onClick={() => addToCart(singleProduct)}
                disabled={isOutOfStock}
              >
                <span className={styles.cartIcon}>
                  <Carticon />
                </span>{" "}
                Add to cart
              </button>
            )}
          </div>

          {/* Additional Information */}
          <div className={styles.additionalInfo}>
            {additionalInfo.map((info, index) => (
              <div key={index} className={styles.infoItem}>
                <span className={styles.infoIcon}>{info.icon}</span>
                <p className={styles.Ftext}>{info.text}</p>
              </div>
            ))}
          </div>
          <div style={{ width: "100%" }}>
            <button
              onClick={toggleDropdown}
              className={styles.productDescriptionButton}
            >
              <div className={styles.productDescriptionTitle}>
                <div className={styles.ucontant}>
                  <Color1 />{" "}
                  <h5 className={styles.text_f}>Product Description</h5>
                </div>
                <div>
                  {isOpen ? (
                    <FaChevronUp className={styles.icon} />
                  ) : (
                    <FaChevronDown className={styles.icon} />
                  )}
                </div>
              </div>
            </button>

            {isOpen && (
              <div
                className={styles.dropdownContent}
                dangerouslySetInnerHTML={{ __html: singleProduct?.description }}
              ></div>
            )}

            {/* Array format for product description */}
            {/* <div className={styles.productDescription}>
              {productDescriptionArray.map((description, index) => (
                <p key={index}>{description}</p>
              ))}
            </div> */}

            {/* Grid layout for product details */}
            <div className={styles.descriptionGrid}>
              {productDetails?.map((item, index) => (
                <div
                  key={index}
                  className={styles[`descriptionItem${index + 1}`]}
                >
                  <p className={styles.label}>{item?.name || "N/A"} </p>
                  <p className={styles.value}>{item?.value || "N/A"} </p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.newBornSection}>
            {/* <img
              src={require("../../assets/images/photo154.png")}
              alt="Baby Clothes Set"
              loading="lazy" 
              className={styles.mainPhoto}
            />

            <h6 className={styles.productTitle}>New Born Baby Clothes Set</h6>

            <ul className={styles.productFeatures}>
              <li>
                The lightweight cotton sleeveless tops are an all-time favorite
                with new moms. We use soft and diverse cotton fabrics which are
                gentle on the baby’s skin. Cambric, Voile, Hosiery etc. are some
                of the cotton fabrics that we work with.
              </li>
              <li>
                Proudly made in India. Our product range includes Dresses,
                Rompers, Newborn Jhabla/vest, Nappy, Swaddle, Blanket, Cap,
                Booties, Wipe/Face Towel etc. for baby girls and baby boys.
              </li>
            </ul>

            {/* Baby Vests Section */}
            {/* <div className={styles.babyVests}>
              <div className={styles.babysts}>
                <h6 className={styles.babyTitle}>
                  Baby Thread-Border Cotton Vests
                </h6>
              </div>
              <div className={styles.babyNext}>
                <div className={styles.vestCard}>
                  <img
                    src={require("../../assets/images/itemsabc.png")}
                    alt="Sleeveless Jhablas"
                    loading="lazy" 
                    className={styles.vestImage}
                  />
                  <p className={styles.vestTitle}>Sleeveless Jhablas</p>
                  <p className={styles.vestDescription}>
                    Front-open, sleeveless thread border cotton baby vests.
                  </p>
                </div>
                <div className={styles.vestCard}>
                  <img
                    src={require("../../assets/images/itemsabc.png")}
                    alt="Half Sleeve Jhablas"
                    loading="lazy" 
                    className={styles.vestImage}
                  />
                  <p className={styles.vestTitle}>Half-Sleeve Jhablas</p>
                  <p className={styles.vestDescription}>
                    Back-open, cap-sleeve thread border cotton baby vests.
                  </p>
                </div>
                <div className={styles.vestCard}>
                  <img
                    src={require("../../assets/images/itemsabc.png")}
                    alt="Full-Sleeve Jhablas"
                    loading="lazy" 
                    className={styles.vestImage}
                  />
                  <p className={styles.vestTitle}>Full-Sleeve Jhablas</p>
                  <p className={styles.vestDescription}>
                    Front-open, full sleeve thread border cotton baby vests.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <button
            onClick={toggleDropdownF}
            className={styles.productDescriptionButton}
          >
            <div className={styles.productDescriptionTitle}>
              <div className={styles.ucontant}>
                <Color1 /> <h5 className={styles.text_f}> Fabric Details</h5>
              </div>
              <div>
                {isOpenf ? (
                  <FaChevronUp className={styles.icon} />
                ) : (
                  <FaChevronDown className={styles.icon} />
                )}
              </div>
            </div> 
          </button>

          {isOpenf && (
            <div className={styles.dropdownContent}>
              {/* Your dropdown content goes here */}
              <p>This is the fabric details content.</p>
            </div>
          )}
          <button
            onClick={toggleDropdownR}
            className={styles.productDescriptionButton}
          >
            <div className={styles.productDescriptionTitle}>
              <div className={styles.ucontant}>
                <Color1 /> <h5 className={styles.text_f}> Reviews</h5>
              </div>
              <div>
                {isOpenr ? (
                  <FaChevronUp className={styles.icon} />
                ) : (
                  <FaChevronDown className={styles.icon} />
                )}
              </div>
            </div>
          </button>

          {isOpenr && (
            <div className={styles.dropdownContent}>
              {/* Your dropdown content goes here */}
              <div className={styles.container44}>
                <div className={styles.ratingSummary}>
                  <div className={styles.ratingBreakdown}>
                    {[5, 4, 3, 2, 1].map((rating, index) => {
                      const ratingCount =
                        reviews?.ratingCounts?.find(
                          (item) => item?._id === rating
                        )?.count || 0;
                      const widthPercentage =
                        totalReviews > 0
                          ? (ratingCount / totalReviews) * 100
                          : 0;

                      return (
                        <div key={index} className={styles.ratingRow}>
                          <span className={styles.star_1}>
                            {rating} <Star12 />
                          </span>
                          <div className={styles.ratingBar}>
                            <div
                              className={styles.filledBar}
                              style={{ width: `${widthPercentage}%` }}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className={styles.averageRating}>
                    <h2 className={styles.ratenum}>{averageStar} </h2>
                    <div className={styles.stars}>
                      <span className={styles.star_1}>
                        <StarRatings
                          rating={averageStar || 0}
                          starRatedColor="gold"
                          numberOfStars={5}
                          starDimension="15px"
                          starSpacing="1px"
                          name="rating"
                        />
                      </span>
                    </div>
                    <p>{reviews?.reviews?.length} Reviews</p>
                  </div>
                </div>

                <div className={styles.reviews}>
                  {reviews?.reviews?.map((review, index) => (
                    <div key={index} className={styles.review}>
                      <div className={styles.reviewHeader}>
                        <img
                          src={`https://www.svgrepo.com/show/486506/user-profile-filled.svg`}
                          alt={review?.name}
                          loading="lazy" 
                          className={styles.avatar}
                        />
                        <div className={styles.reviewInfo}>
                          <h3>{review?.userName}</h3>
                          <p>
                            <StarRatings
                              rating={review?.rating || 0}
                              starRatedColor="gold"
                              numberOfStars={5}
                              starDimension="15px"
                              starSpacing="1px"
                              name="rating"
                            />
                            <span className={styles.time}>
                              {formattedDate(review.createdAt)}
                            </span>
                          </p>
                        </div>
                        <div className={styles.moreOptions}>⋮</div>
                      </div>
                      <p className={styles.reviewText}>{review.comment}</p>
                      <div>
                        {review?.images?.map((image, index) => (
                          <img
                            key={index}
                            loading="lazy" 
                            src={image}
                            width={50}
                            alt={`Review Image ${index + 1}`}
                            style={{ marginRight: "2%" }}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* similerproduct slider */}
{similarProducts.length > 0 ? (
      <div className={styles.similerproduct}>
        <div className={styles.similer_nav}>
          <div className={styles.nav_sim}>
            <h4 className={styles.sim_heading}>Similar Products</h4>
            <div className={styles.navbutton}>
              <div
                className={styles.prev}
                style={{cursor:"pointer"}}
                onClick={() => sliderRef.current?.slickPrev()}
              >
                <HomePrev />
              </div>
              <div
                className={styles.next}
                style={{cursor:"pointer"}}
                onClick={() => sliderRef.current?.slickNext()}
              >
                <HomeNext />
              </div>
            </div>
          </div>
        </div>
        <Slider {...sliderSettings} ref={sliderRef}>
          {similarProducts?.map((product) => (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={`/Category Products/Product details/${product._id}`}
              style={{ textDecoration: "none" }}
              key={product._id}
            >
              <div className={styles.slider1}>
                <div className={styles.sec}>
                  <div className={styles.sec01}>
                    <div className={styles.maincontainer}>
                      <img
                        loading="lazy" 
                        src={product.featuredImage}
                        alt={product.name}
                        className={styles.SlideImage}
                      />
                      {product.sale && (
                        <div style={{ position: "absolute", top: 0 }}>
                          <p className={styles.sale}>SALE</p>
                        </div>
                      )}
                      <div className={styles.content}>
                        <div className={styles.right}>
                          <span className={styles.name}>{product.name}</span>
                          <div className={styles.stars}>
                            <StarRatings
                              rating={product?.averageRating || 0}
                              starRatedColor="gold"
                              numberOfStars={5}
                              starDimension="15px"
                              starSpacing="1px"
                              name="rating"
                            />
                          </div>
                          {product?.stockQuantity > 0 ? (
                            <div className={styles.price}>
                              <h5 className={styles.discoutprice}>
                                {currency === "USD" ? "$" : "₹"}
                                {(currency === "USD"
                                  ? product.salePrice * rate
                                  : product.salePrice
                                ).toFixed(2)}
                              </h5>
                              <h5 className={styles.beforeprice}>
                                {currency === "USD" ? "$" : "₹"}
                                {(currency === "USD"
                                  ? product.basePrice * rate
                                  : product.basePrice
                                ).toFixed(2)}
                              </h5>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "red",
                                marginBottom: "8%",
                                fontSize: "14px",
                              }}
                            >
                              Out of stock
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.right}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            className={styles.Like}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (token) {
                                handleAddToWishList(product);
                              } else {
                                nav("/login");
                                toast.warning("Please login");
                              }
                            }}
                          >
                            {wishlistData.some(
                              (wishlistItem) =>
                                wishlistItem?.item?._id === product._id
                            ) ? (
                              <FavoriteIcon
                                style={{
                                  width: "25px",
                                  color: "red",
                                  border: "none",
                                }}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                sx={{
                                  stroke: "#ffffff",
                                  strokeWidth: 1,
                                  width: "25px",
                                  color: "black",
                                }}
                              />
                            )}
                          </div>
                          {product?.stockQuantity < 1 ? (
                            <div
                              className={styles.AddCart}
                              style={{ color: "red" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  subscribeToOutofstockProducts(product?._id)
                                );
                              }}
                            >
                              + Notify me
                            </div>
                          ) : (
                            <>
                              {cartData?.some(
                                (cartItem) =>
                                  cartItem?.item?._id === product._id
                              ) ? (
                                <button
                                  className={styles.AddCart}
                                  disabled
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "not-allowed",
                                    color: "green",
                                  }}
                                >
                                  Added to Cart
                                </button>
                              ) : (
                                <>
                                  <Link to="/mycart">
                                    <button
                                      className={styles.AddCart}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (token) {
                                          handleAddToCart(product);
                                        } else {
                                          nav("/login");
                                          toast.warning("Please Login");
                                        }
                                      }}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      + Add to Cart
                                    </button>
                                  </Link>
                                  {product?.stockQuantity <= 5 ? (
                                    <div
                                      style={{
                                        color: "orange",
                                        fontWeight: 400,
                                        fontSize: 12,
                                        textDecoration: "none",
                                      }}
                                    >
                                      Only {product?.stockQuantity} left in
                                      stock
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
      ) : null}
    </div>
    )}
   </>
  );
};

export default Productpage;
