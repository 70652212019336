import React, { useEffect, useState } from 'react';
import ProfileBody from '../../dashcomponents/profilebody/ProfileBody';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./myprofiledetails.module.css";
import api from '../../../utils/Api';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';

const MyprofileDetails = () => {
    const [profileData, setProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const nav = useNavigate();

    // Fetch profile details
    const getDetails = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await api.getProfile();
            if (response?.data) {
                setProfileData(response?.data?.data?.data);
            }
        } catch (err) {
            console.error("Error fetching profile details:", err);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    console.log("profile data", profileData);

    useEffect(() => {
        getDetails();
    }, []);

    if (isLoading) {
        // Show CircularProgress while loading
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div style={{ display: "flex" }}>
            <ProfileMenu />
            <div className={styles.myprofile}>
                <div className={styles.prodetailsContainer}>
                    <div className={styles.profileheader}>
                        <h2 className={styles.pheading}>My Profile</h2>
                        <Link
                            to="/Edit Profile"
                            state={{ profileData }} // Pass data if needed in the destination
                        >
                            <button className={styles.editButton}>
                                Edit profile
                            </button>
                        </Link>
                    </div>
                    <div className={styles.profilebodyContent}>
                        <div className={styles.prodetails}>
                            <div className={styles.prorow}>
                                <div className={styles.procustomer}>Customer</div>
                                <div className={styles.procname}>{profileData?.name}</div>
                            </div>
                            <div className={styles.prorow}>
                                <div className={styles.procustomer}>Mobile Number</div>
                                <div className={styles.procname}>
                                    {profileData?.countryCode} {profileData?.phone}
                                </div>
                            </div>
                            <div className={styles.prorow}>
                                <div className={styles.procustomer}>Email ID</div>
                                <div className={styles.procname} style={{ flex: 1 }}>{profileData?.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyprofileDetails;
