import React from 'react';
import ProfileBody from '../../dashcomponents/profilebody/ProfileBody';
import styles from "./myprofile.module.css"
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';

 
const Myprofile = () => {
    return (
        <>
       <div className={styles.myprofile}>
       
            <ProfileMenu selected={"myprofiledetails"}/>
            <ProfileBody />
    
       </div>
          
      
        </>
    );
};

export default Myprofile;
