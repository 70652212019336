import React from 'react';
import ProfileBody from '../../dashcomponents/profilebody/ProfileBody';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./emptyaddress.module.css"

const EmptyAddress = () => {
    return (
        <>
            <div className={styles.myprofile}>
                <div className={styles.prodetailsContainer}>
                    <div className={styles.profileheader}>
                        <h2>My Address</h2>
                        <button className={styles.editButton} /* onClick={handleEdit} */>
                            {/* {isEditing ? 'Save' : 'Edit profile'} */}
                            Add new address
                        </button>
                    </div>
                    <div className={styles.profilebodyContent}>

                        <span>No address added yet!</span>

                    </div>
                </div>
            </div>


        </>
    );
};

export default EmptyAddress;
