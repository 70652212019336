import React, { useEffect, useState } from "react";
import styles from "./checkout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../utils/Api";
import { setCart } from "../../redux/reducers/cartSlice";
import { getAllAddress, addNewAddress } from "../../redux/reducers/addressSlice";
import toast from "../../utils/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Plus } from "../../Svg";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAddress());
  }, [dispatch]);

  const { cartData } = useSelector((state) => state.cart);
  const { addressData } = useSelector((state) => state.address);

  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";


  const getFutureDate = (daysToAdd) => {
    const today = new Date();
    today.setDate(today.getDate() + daysToAdd);

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return today.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
};

const futureDate = getFutureDate(5);

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    houseNumber: "",
    apartment: "",
    companyName: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    isDefault: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone must be 10 digits")
      .required("Phone is required"),
    houseNumber: Yup.string().required("House address is required"),
    zip: Yup.string()
      .matches(/^\d{6}$/, "Zip code must be 6 digits")
      .required("Zip code is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      
      console.log('first',values)
      dispatch(
        addNewAddress({
          ...values,
          streetAddress: `${values.houseNumber}, ${values.addressLine2}`,
        })
      );
    },
  });

  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleAddressChange = (id) => {
    setSelectedAddressId(id);
    setSelectedAddress(addressData?.find((address) => address._id === id));
  };

  const handleAddAddressClick = () => {
    setIsFormVisible(!isFormVisible);
    setSelectedAddress(null)
  };

  useEffect(() => {
    const defaultAddress = addressData?.find((address) => address.isDefault);
    if (defaultAddress) {
      setSelectedAddressId(defaultAddress._id);
      setSelectedAddress(defaultAddress);
    }
  }, [addressData]);

  const baseTotal = cartData?.reduce(
    (acc, item) => acc + (item?.item?.basePrice || 0) * (item?.count || 0),
    0
  ) * rate;

  const saleTotal = cartData?.reduce(
    (acc, item) => acc + (item?.item?.salePrice || 0) * (item?.count || 0),
    0
  ) * rate;

  console.log('formik values',formik.values)

  const handleRazorpayPayment = async () => {
    try {
      // Step 1: Check for Formik validation if no address is selected
      if (!selectedAddress) {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length > 0) {
          console.log(errors);
          formik.handleSubmit();
          toast.warning("Please select or add new address.");
          return;
        }
      }
  
      // Step 2: Proceed with Razorpay payment initiation
      const paymentResponse = await api.paymentStepOne({
        grandTotal: saleTotal * 100, // Convert to the smallest currency unit (e.g., paise)
        currency,
      });
      const { order } = paymentResponse.data.data;
  
      // Step 3: Determine which address to use (Formik values > selectedAddress)
      const billingDetails = formik.values.firstName && formik.values.lastName ? {
        // Use Formik values if filled
        billing_customer_name: formik.values.firstName,
        billing_last_name: formik.values.lastName,
        billing_address: `${formik.values.houseNumber}, ${formik.values.apartment}`,
        billing_city: formik.values.city,
        billing_pincode: formik.values.zip,
        billing_state: formik.values.state,
        billing_country: formik.values.country,
        billing_email: formik.values.email,
        billing_phone: formik.values.phone,
      } : selectedAddress ? {
        // Use selected address if Formik values are not present
        billing_customer_name: selectedAddress.firstName,
        billing_last_name: selectedAddress.lastName,
        billing_address: selectedAddress.streetAddress,
        billing_city: selectedAddress.city,
        billing_pincode: selectedAddress.zip,
        billing_state: selectedAddress.state,
        billing_country: selectedAddress.country,
        billing_email: selectedAddress.email,
        billing_phone: selectedAddress.phone,
      } : null; // In case neither Formik nor selectedAddress is available, handle error case.
  
      if (!billingDetails) {
        toast.error("No valid billing address available.");
        return;
      }
      console.log("billing detailos",billingDetails)
      // Razorpay payment options
      const options = {
        key: process.env.RAZORPAY_KEY, // Use your Razorpay key
        amount: order.amount, // Amount in paise (smallest currency unit)
        currency: currency,
        order_id: order.id,
        handler: async function (response) {
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
  
          // Send payment confirmation along with billing details
          await api.paymentStepTwo({
            razorpay_payment_id,
            razorpay_order_id,
            razorpay_signature,
            cartData,
            grandTotal: saleTotal,
            sub_total: saleTotal,
            payment_method: "Prepaid",
            currency: currency || "INR",
            currencyValue: 1 / rate || 1,
            ...billingDetails, // Always use the valid billing details from either Formik or selectedAddress
          });
  
          // Navigate to the thank you page after successful payment
          navigate("/thank");
  
          // Clear the cart after payment is complete
          dispatch(setCart([]));
        },
      };
  
      // Open Razorpay payment gateway
      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", () => {
        alert("Payment failed, please try again.");
      });
  
      razorpay.open();
    } catch (error) {
      console.error("Payment error:", error);
    }
  };


  

  const handleZipChange = async (e) => {
    const { value } = e.target;
    formik.handleChange(e);

    if (value.length === 6) {
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${value}`);
        const postData = response.data[0];
        if (postData.Status === "Success" && postData.PostOffice.length > 0) {
          const { State, District, Country, Name } = postData.PostOffice[0];
          formik.setValues((prevValues) => ({
            ...prevValues,
            state: State,
            city: Name,
            country: Country,
          }));
        } else {
          formik.setFieldError('zip', 'Invalid pincode or no data found');
        }
      } catch (error) {
        console.error("Error fetching pincode data:", error);
      }
    }
  };
  return (
    <div className={styles.chekoutContainer}>
      <div className={styles.chekoutForm}>
        <div className={styles.hline}></div>

        <div className={styles.addressContainer}>
          <h2>Shipping Address</h2>
          {addressData?.map((address) => (
            <div
              key={address?.id}
              className={`${styles  } ${
                selectedAddressId === address._id ? styles.selected : ""
              }`}
            >
              <div className={styles.addressHeader}>
                <div className={styles.addressSubHeader}>
                  <input
                    type="radio"
                    id={`address-${address?._id}`}
                    name="address"
                    checked={selectedAddressId === address?._id}
                    onChange={() => handleAddressChange(address?._id)}
                  />
                  <label
                    htmlFor={`address-${address?._id}`}
                    className={styles.addressName}
                  >
                    {address.firstName + " " + address.lastName}
                  </label>
                </div>
                <span className={styles.editIcon} onClick={() => navigate("/addaddress", { state: { addressData } })}>✏️</span>
              </div>
              <p className={styles.addressDetails}>
                {address?.streetAddress +
                  " " +
                  address?.city +
                  " " +
                  address?.zip}
              </p>
              <p className={styles.addressPhone}>(+91) {address?.phone}</p>
            </div>
          ))}
          <div className={styles.addNewAddress}>
            <button
              className={styles.addAddressButton}
              onClick={handleAddAddressClick}
            >
              <Plus/><span className={styles.bottomline}>Add New Address</span>
            </button>
          </div>
          {isFormVisible && (
            <div className={styles.chekoutCustminfo}>
              <form
                className={styles.profileform} /* onSubmit={handleSubmit} */
              >
                {localStorage.getItem("token") ? null : (
                  <div className={styles.chekoutCustminfo}>
                    <h2>Customer information</h2>
                    <p>
                      Already have an account?
                      <span onClick={() => navigate("/login")}>Log in</span>
                    </p>
                    <div className={styles.profileformGroup}>
                      <input
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email Address *"
                        // required
                      />
                    </div>
                  </div>
                )}

           

     


<form className={styles.profileform} onSubmit={formik.handleSubmit}>
          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="First name *"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <p className={styles.errorText}>{formik.errors.firstName}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Last name *"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <p className={styles.errorText}>{formik.errors.lastName}</p>
              )}
            </div>
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Phone *"
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className={styles.errorText}>{formik.errors.phone}</p>
            )}
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="text"
              name="houseNumber"
              value={formik.values.houseNumber}
              onChange={formik.handleChange}
              placeholder="House number and street name"
            />
               {formik.touched.houseNumber && formik.errors.houseNumber && (
                <p className={styles.errorText}>{formik.errors.houseNumber}</p>
              )}
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="text"
              name="addressLine2"
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              placeholder="Apartment, suite, unit, etc. (optional)"
            />
          </div>

          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="City *"
              />
              {formik.touched.city && formik.errors.city && (
                <p className={styles.errorText}>{formik.errors.city}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="zip"
                value={formik.values.zip}
                onChange={handleZipChange}
                onBlur={formik.handleBlur}
                placeholder="Zip *"
              />
              {formik.touched.zip && formik.errors.zip && (
                <p className={styles.errorText}>{formik.errors.zip}</p>
              )}
            </div>
          </div>

          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="State *"
              />
              {formik.touched.state && formik.errors.state && (
                <p className={styles.errorText}>{formik.errors.state}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Country *"
              />
              {formik.touched.country && formik.errors.country && (
                <p className={styles.errorText}>{formik.errors.country}</p>
              )}
            </div>
          </div>

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              name="isDefault"
              checked={formik.values.isDefault}
              onChange={formik.handleChange}
            />
            <label>Make this the default address</label>
          </div>

          <div className={styles.buttonGroup}>
      
          </div>
        </form>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className={styles.chekoutOrder}>
        <div className={styles.orderSummary}>
          <div>
            <h2>Order Summary</h2>
          </div>
          <div className={styles.orderdeets}>
            <p>Price:</p>
            <span>
              {currencySymbol} {baseTotal?.toFixed(2)}
            </span>
          </div>
          <div className={styles.orderdeets}>
            <p>Discount:</p>
            <span>
              {currencySymbol} {(baseTotal - saleTotal).toFixed(2)}
            </span>
          </div>
          <div className={styles.orderdeets}>
            <p>Shipping:</p>
            <span>Free</span>
          </div>
          <div className={styles.hline}></div>
          <div className={styles.orderdeets}>
            <p>Total:</p>
            <span>
              {currencySymbol} {saleTotal.toFixed(2)}
            </span>
          </div>
          <div className={styles.orderdeets}>
            <p>Estimated Delivery by:</p>
            <span className={styles.bold}>{futureDate}</span>
          </div>
          <button
            type="button"
            onClick={handleRazorpayPayment}
            className={styles.saveButton}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
