import React, { useState } from 'react';
import styles from './login.module.css';
import Authheader from '../../smallcomponents/authheader/Authheader';
import { useDispatch } from 'react-redux';
import { loginWithPhone,loginWithGoogle } from '../../redux/reducers/userSlice';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    signInWithPopup,
    GoogleAuthProvider,
  } from "firebase/auth";
  import { auth } from "../../utils/firebase";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");



  const handleInputChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phone) {
      setError('Please enter a phone number');
      return;
    }

    try {
      localStorage.setItem('phone', phone);

      const resultAction = await dispatch(loginWithPhone({ phone }));
      unwrapResult(resultAction); 
      

   
      navigate(`/otp?mode=login`); 
    } catch (error) {
      setError('Something went wrong, please try again.');
      console.error('Error during signup:', error);
    }
  };


  const handleGoogleSignUp = async (e) => {
    e.preventDefault();

     // Instantiate a GoogleAuthProvider object
    const provider = new GoogleAuthProvider();
    
    try {
      // Sign in with a pop-up window
      const result = await signInWithPopup(auth, provider);

      // Pull signed-in user credential.
      const user = result.user;

      console.log("sasaas",result)

      const resultAction = await dispatch(loginWithGoogle({
        email:user.email,
        googleIdToken:user.accessToken
      }));

      unwrapResult(resultAction);

      navigate("/")
      
    } catch (err) {
      // Handle errors here.
      const errorMessage = err.message;
      const errorCode = err.code;

      setError(true);

      switch (errorCode) {
        case "auth/operation-not-allowed":
          setGoogleErrorMessage("Email/password accounts are not enabled.");
          break;
        case "auth/operation-not-supported-in-this-environment":
          setGoogleErrorMessage("HTTP protocol is not supported. Please use HTTPS.")
          break;
        case "auth/popup-blocked":
          setGoogleErrorMessage("Popup has been blocked by the browser. Please allow popups for this website.")
          break;
        case "auth/popup-closed-by-user":
          setGoogleErrorMessage("Popup has been closed by the user before finalizing the operation. Please try again.")
          break;
        default:
          setGoogleErrorMessage(errorMessage);
          break;
      }
    }
  };


  return (
    <>
      <Authheader />
      <div className={styles.login_container}>
        <div className={styles.image_section}>
          <img src="./assets/images/babylogin.png" alt="Baby" />
        </div>
        <div className={styles.form_section}>
          <h2>Welcome Back!</h2>
          <p>Please login here</p>
          <div className={styles.form_container}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="phone">PHONE NUMBER</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter phone number"
                value={phone}
                onChange={handleInputChange}
              />
              {error && <p className={styles.error}>{error}</p>}
              <button type="submit">Login</button>
            </form>
            <div className={styles.newuser}>
              <p>
                New user? &nbsp;
                <a href="/signup" className={styles.signup}>
                  Signup
                </a>
              </p>
            </div>
            <div className={styles.separator}>
              <span>OR</span>
            </div>
            <div className={styles.reactbutton}>
            <button className={styles.login} onClick={handleGoogleSignUp}>
              <img src="./assets/images/google.png" alt="Google Icon" />
              Login with Google
            </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
