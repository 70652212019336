import React, { useState } from "react";
import styles from "./requestpopup.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setData } from "../../redux/reducers/orderSlice";

const RequestPopup = ({ onClose, data, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  console.log('selected',selectedItems)

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        const updatedItems = prevSelectedItems.filter((i) => i !== item);
        dispatch(
          setData({
            name: "selectedItemsToExchange",
            value: updatedItems,
          })
        );
        return updatedItems;
      } else {
        const updatedItems = [...prevSelectedItems, item];
        dispatch(
          setData({
            name: "selectedItemsToExchange",
            value: updatedItems,
          })
        );
        return updatedItems;
      }
    });
  };

  const handleExchange = () => {
    if (selectedItems.length > 0) {
      dispatch(setData({ name: "orderToExchange", value: { ...data, order_items: selectedItems } }));
      navigate(`/returnexchange/${id}`);
    }
  };

  return (
    <div className={styles.blur_background}>
      <div className={styles.popup_card}>
        <div className={styles.popup_head}>
          <div>
            <span>Request for Exchange</span>
          </div>
          <div>
            <button className={styles.close_btn} onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div>
          <p className={styles.reviewsub}>
            Select the products to request for exchange
          </p>
        </div>

        {data?.order_items?.map((item, index) => (
          <div className={styles.reviewprod} key={index}>
            <input
              type="checkbox"
              name={`product_${index}`}
              onChange={() => handleCheckboxChange(item)}
              checked={selectedItems.includes(item)}
            />
            <div>
              <img
                width={40}
                src={
                  item?.productId?.featuredImage ||
                  "https://via.placeholder.com/50"
                }
                alt={item?.name}
              />
            </div>
            <div>{item?.name || "Product name"}</div>
          </div>
        ))}

        <div className={styles.orderbuttons}>
          <button className={styles.trackOrderBtn} onClick={onClose}>
            Cancel
          </button>
          <button
            className={styles.invoiceBtn}
            onClick={handleExchange}
            disabled={selectedItems.length === 0} 
          >
            Exchange
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestPopup;
