import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import styles from "./profilemenu.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutConfirmation from "../../../components/logout/logout";
import api from "../../../utils/Api";

const ProfileMenu = ({ selected }) => {
  const [selectedMenu, setSelectedMenu] = useState("My Profile");
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    if (selected !== undefined) setSelectedMenu(selected);
  }, [selected]);

  const menuItems = [
    { id: "My Profile", label: "My Profile", icon: <MyProfileIcon /> },
    { id: "Order history", label: "Order History", icon: <OrderHistoryIcon /> },
    { id: "Wishlist", label: "Wishlist", icon: <WishlistIcon /> },
    { id: "Shopping Cart", label: "Shopping Cart", icon: <ShoppingCartIcon /> },
    { id: "My Address", label: "Address", icon: <AddressIcon /> },
    { id: "logout", label: "Log-out", icon: <LogoutIcon /> },
  ];

  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const handleClosePopup = () => {
    setShowLogoutPopup(false);
  };

  const handleConfirmLogout = () => {
    setShowLogoutPopup(false);
    // Add logout logic here, e.g., clearing tokens, redirecting to login, etc.
    console.log("User logged out");
  };

  // Fetch profile details
  const getDetails = async () => {
    try {
      const response = await api.getProfile();
      if (response?.data) {
        setProfileData(response?.data?.data?.data);
      }
    } catch (err) {
      console.error("Error fetching profile details:", err);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  console.log(profileData);

  return (
    <div className={styles.container1}>
      <div className={styles.main}>
        <div className={styles.profileMenu}>
          <div className={styles.userInfo}>
            <img
              src="https://www.svgrepo.com/show/486506/user-profile-filled.svg"
              alt="User Avatar"
              className={styles.profileAvatar}
            />
            <span className={styles.userName}>
              {profileData?.name
                ? profileData.name.charAt(0).toUpperCase() +
                  profileData.name.slice(1)
                : ""}
            </span>
          </div>
          <ul className={styles.profilemenuList}>
            {menuItems.map((item) => (
              <li
                key={item.id}
                className={`${styles.profilemenuItem} ${
                  selectedMenu === item.id ? styles.profileactive : ""
                }`}
                onClick={() => {
                  if (item.id === "logout") {
                    handleLogoutClick();
                    setSelectedMenu("logout");
                  } else {
                    setSelectedMenu(item.id);
                    nav(`/${item.id}`);
                  }
                }}
              >
                <span className={styles.profileicon}>{item.icon}</span>
                <div className={styles.poin}>{item.label}</div>
              </li>
            ))}
          </ul>
        </div>

        <Dialog
          open={showLogoutPopup}
          onClose={handleClosePopup}
          aria-labelledby="logout-dialog-title"
          aria-describedby="logout-dialog-description"
        >
          <DialogTitle id="logout-dialog-title">{"Confirm Logout"}</DialogTitle>
          <LogoutConfirmation onClose={handleClosePopup} />
        </Dialog>
      </div>
    </div>
  );
};

// Example icons for your menu items
const MyProfileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 21H13V15H21V21ZM11 21H3V11H11V21ZM21 13H13V3H21V13ZM11 9H3V3H11V9Z"
      fill="#1A1A1A"
    />
  </svg>
);

const OrderHistoryIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 20V13H11L7.783 16.22C8.33247 16.7819 8.98837 17.2287 9.71241 17.5343C10.4364 17.8399 11.2141 17.9983 12 18C13.2393 17.9982 14.4475 17.6127 15.4589 16.8965C16.4702 16.1802 17.2349 15.1684 17.648 14H17.666C17.78 13.675 17.867 13.34 17.925 13H19.937C19.6934 14.9333 18.7527 16.7111 17.2913 18C15.83 19.2888 13.9485 20 12 20H11.99C10.9398 20.0032 9.89944 19.798 8.9291 19.3963C7.95876 18.9946 7.07772 18.4045 6.337 17.66L4 20ZM6.074 11H4.062C4.30548 9.06751 5.24564 7.29019 6.70616 6.00145C8.16667 4.7127 10.0472 4.00108 11.995 4.00004H12C13.0504 3.99671 14.0909 4.20183 15.0615 4.6035C16.032 5.00517 16.9132 5.59541 17.654 6.34004L20 4.00004V11H13L16.222 7.78004C15.672 7.21752 15.0153 6.77035 14.2903 6.46471C13.5654 6.15907 12.7867 6.0011 12 6.00004C10.7607 6.00187 9.55246 6.38738 8.54114 7.10361C7.52982 7.81985 6.76508 8.83166 6.352 10H6.334C6.219 10.325 6.132 10.66 6.075 11H6.074Z"
      fill="#CCCCCC"
    />
  </svg>
);

const WishlistIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9997 19.0538C-7.99987 7.99967 6.00011 -4.00033 11.9997 3.58772C18.0001 -4.00034 32.0001 7.99967 11.9997 19.0538Z"
      stroke="#CCCCCC"
      strokeWidth="1.5"
    />
  </svg>
);

const ShoppingCartIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10H5L3 21H21L19 10H16M8 10V7C8 4.79086 9.79086 3 12 3V3C14.2091 3 16 4.79086 16 7V10M8 10H16M8 10V13M16 10V13"
      stroke="#CCCCCC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AddressIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6603 14.4939C17.5224 13.5831 18.0986 12.4397 18.3179 11.2049C18.5371 9.97016 18.3897 8.69822 17.8939 7.54632C17.3981 6.39442 16.5756 5.41305 15.5281 4.72352C14.4807 4.03398 13.2541 3.6665 12 3.6665C10.7459 3.6665 9.51935 4.03398 8.47186 4.72352C7.42438 5.41305 6.60191 6.39442 6.10611 7.54632C5.61031 8.69822 5.46291 9.97016 5.68215 11.2049C5.90138 12.4397 6.47763 13.5831 7.33967 14.4939C7.83436 13.7045 8.52167 13.047 9.3426 12.5725C10.1635 12.0979 11.095 11.8192 12.0481 11.7624C13.0013 11.7055 13.9475 11.8722 14.8196 12.2524C15.6918 12.6326 16.4654 13.217 17.0934 13.9614M12 21C14.7091 21 17 18.7091 17 16C17 13.2909 14.7091 11 12 11C9.29086 11 7 13.2909 7 16C7 18.7091 9.29086 21 12 21Z"
      stroke="#CCCCCC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PaymentCardsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="4" width="18" height="16" rx="3" fill="#CCCCCC" />
    <rect x="3" y="9" width="18" height="4" fill="white" />
    <path
      d="M9 16H5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 16H17"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 3H5C4.44772 3 4 3.44772 4 4V20C4 20.5523 4.44772 21 5 21H15C15.5523 21 16 20.5523 16 20V4C16 3.44772 15.5523 3 15 3Z"
      stroke="#CCCCCC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8L21 10M21 10L19 12M21 10H12"
      stroke="#CCCCCC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileMenu;
