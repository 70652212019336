import React from 'react';
import ProfileBody from '../../dashcomponents/profilebody/ProfileBody';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./emptycard.module.css"

const EmptyCard = () => {
    return (
        <>
            <div className={styles.myprofile}>
             
                <div className={styles.prodetailsContainer}>
                    <div className={styles.profileheader}>
                        <h2>Payment Cards</h2>
                        <button className={styles.editButton} /* onClick={handleEdit} */>
                            {/* {isEditing ? 'Save' : 'Edit profile'} */}
                            Add new card
                        </button>
                    </div>
                    <div className={styles.profilebodyContent}>

                        <span>No card added yet!</span>

                    </div>
                </div>
            </div>


        </>
    );
};

export default EmptyCard;
