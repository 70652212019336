import React from 'react';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./addaddress.module.css"
import AddressBody from '../../dashcomponents/addressbody/AddressBody';
 
const AddAddress = () => {
    return (
        <>
       <div className={styles.myprofile}>
        <ProfileMenu selected={"My Address"} /> 
            <AddressBody />
       </div>
          
      
        </>
    );
};

export default AddAddress;
