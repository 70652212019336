import {createAsyncThunk , createSlice} from "@reduxjs/toolkit"
import api from "../../utils/Api"
import toast from "../../utils/toast"


const initialState = {
    cartData:[],
    isLoading:false,
    error:null
}





export const getCartProducts = createAsyncThunk("getCartProducts" , async (body , {rejectWithValue , dispatch}) => {
    try {

      dispatch(
        setData({
          name: "isLoading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );

        const { data, status } = await api.getCart();
  

        if (status === 200) {

          dispatch(
            setData({
              name: "isLoading",
              value: false,
            })
          );

            console.log("cartdata",data.data.data);
            
            dispatch(setCart(data.data.data));
        }
        
    } catch (error) {
      dispatch(
        setData({
          name: "isLoading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const addProductToCart = createAsyncThunk("addToCart" , async(body ,{ rejectWithValue, dispatch }) => {
    try {
        console.log("cart boooody",body)

        const {data,status} = await api.addProductToCart(body)

        if(status === 200 ){
         await   dispatch(getCartProducts())
            toast.success("Cart Updated");
        }
        
    } catch (error) {
      toast.error(error.response?.data?.message)
        return rejectWithValue( error.response?.data?.message || "Something went wrong. Please try again later."
        );
    }
 })

export const removeFromCart = createAsyncThunk( "removeFromCart",async (body, { rejectWithValue, dispatch }) => {
      try {
        console.log("body",body)
        const { data, status } = await api.removeProductFromCart(body); 

        
        if (status === 200) {
          dispatch(getCartProducts());
          toast.warning(data.data.message);
        }
      } catch (error) {
        return rejectWithValue( error.response?.data?.message || "Something went wrong. Please try again later."
        );
      }
    }
  );
  



const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{
       setCart:(state,action) => {
        state.cartData = action.payload;
       },
       setData: (state, action) => {
        state[action.payload.name] = action.payload.value;
      },
    }
})


export const {setCart,setData} = cartSlice.actions
export default cartSlice.reducer

