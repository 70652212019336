import React, { useState } from "react";
import styles from "./signup.module.css";
import Authheader from "../../smallcomponents/authheader/Authheader";
import { useDispatch, useSelector } from "react-redux";
import { signUpWithPhone,signUpWithGoogle} from "../../redux/reducers/userSlice";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
    signInWithPopup,
    GoogleAuthProvider,
  } from "firebase/auth";
  import { auth } from "../../utils/firebase";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, loading, success } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [error, setError] = useState(false);
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");


  console.log("formdata", formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      localStorage.setItem("phone", formData.phone);
      
      const resultAction = await dispatch(signUpWithPhone(formData));

      unwrapResult(resultAction);

      navigate(`/otp?mode=signup`);
    } catch (error) {}
  };

  const handleGoogleSignUp = async (e) => {
    e.preventDefault();

     // Instantiate a GoogleAuthProvider object
    const provider = new GoogleAuthProvider();
    
    try {
      // Sign in with a pop-up window
      const result = await signInWithPopup(auth, provider);

      // Pull signed-in user credential.
      const user = result.user;

      console.log("sasaas",result)

      const resultAction = await dispatch(signUpWithGoogle({
        email:user.email,
        googleIdToken:user.accessToken
      }));

      unwrapResult(resultAction);

      navigate("/")
      
    } catch (err) {
      // Handle errors here.
      const errorMessage = err.message;
      const errorCode = err.code;

      setError(true);

      switch (errorCode) {
        case "auth/operation-not-allowed":
          setGoogleErrorMessage("Email/password accounts are not enabled.");
          break;
        case "auth/operation-not-supported-in-this-environment":
          setGoogleErrorMessage("HTTP protocol is not supported. Please use HTTPS.")
          break;
        case "auth/popup-blocked":
          setGoogleErrorMessage("Popup has been blocked by the browser. Please allow popups for this website.")
          break;
        case "auth/popup-closed-by-user":
          setGoogleErrorMessage("Popup has been closed by the user before finalizing the operation. Please try again.")
          break;
        default:
          setGoogleErrorMessage(errorMessage);
          break;
      }
    }
  };

  return (
    <>
      <Authheader />
      <div className={styles.login_container}>
        <div className={styles.image_section}>
          <img src="./assets/images/babylogin.png" alt="Baby" />
        </div>
        <div className={styles.form_section}>
          <h2>Welcome!</h2>
          <p>Please signup here</p>
          <div className={styles.form_container}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">FULL NAME</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleInputChange}
              />

              <label htmlFor="phone">PHONE NUMBER</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter number"
                value={formData.phone}
                onChange={handleInputChange}
              />

              <label htmlFor="email">EMAIL ADDRESS</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
              />

              <div className={styles.terms_main}>
                <div>
                  <input
                    type="checkbox"
                    id="terms"
                    className={styles.checkbox}
                  />
                </div>
                <div className={styles.terms}>
                  <span>
                    {" "}
                    I agree to the{" "}
                    <a href="/terms&conditions" className={styles.terms_cond}>
                      Terms & Conditions
                    </a>
                  </span>
                </div>
              </div>

              <button type="submit" disabled={loading}>
                {loading ? "Signing Up..." : "Signup"}
              </button>

              {error && <p className={styles.error}>{error}</p>}
              {success && <p className={styles.success}>Signup successful!</p>}
            </form>

            <div className={styles.newuser}>
              <p>
                Already have an account? &nbsp;{" "}
                <a href="/login" className={styles.signup}>
                  Login
                </a>
              </p>
            </div>
            <div className={styles.separator}>
              <span>OR</span>
            </div>
            <div className={styles.Reactbutton}>
            <button className={styles.login}  onClick={handleGoogleSignUp}>
              <img src="./assets/images/google.png" alt="Google Icon" />
              Signup with Google
            </button>
            {error && <p>{googleErrorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
