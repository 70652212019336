import React from 'react';
import styles from './reviewPage.module.css';
import { Star12 } from '../../Svg';

const ReviewPage = () => {
  return (
    <div className={styles.container44}>
      <div className={styles.ratingSummary}>
      <div className={styles.ratingBreakdown}>
          {[5, 4, 3, 2, 1].map((rating, index) => (
            <div key={index} className={styles.ratingRow}>
              <span className={styles.star_1}>{rating} <Star12/></span>
              <div className={styles.ratingBar}>
                <div className={styles.filledBar} style={{ width: `${rating * 20}%` }}></div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.averageRating}>
          <h2 className={styles.ratenum}>4.0</h2>
          <div className={styles.stars}>
            <span  className={styles.star_1}><Star12/> <Star12/> <Star12/> <Star12/> <Star12/></span>
          </div>
          <p>52 Reviews</p>
        </div>
      
      </div>

      <div className={styles.reviews}>
        {[
          {
            name: "Courtney Henry",
            time: "2 mins ago",
            rating: 5,
            text: "Consequat velit qui adipisicing sunt do rependerit ad laborum tempor ullamco exercitation. Ullamco tempor adipisicing et voluptate duis sit esse aliqua."
          },
          {
            name: "Cameron Williamson",
            time: "2 mins ago",
            rating: 4,
            text: "Consequat velit qui adipisicing sunt do rependerit ad laborum tempor ullamco."
          },
          {
            name: "Jane Cooper",
            time: "2 mins ago",
            rating: 3,
            text: "Ullamco tempor adipisicing et voluptate duis sit esse aliqua esse ex."
          }
        ].map((review, index) => (
          <div key={index} className={styles.review}>
            <div className={styles.reviewHeader}>
              <img src={`https://via.placeholder.com/40`} alt={review.name} className={styles.avatar} />
              <div className={styles.reviewInfo}>
                <h3>{review.name}</h3>
                <p>
                  {'★'.repeat(review.rating) + '☆'.repeat(5 - review.rating)}
                  <span className={styles.time}>{review.time}</span>
                </p>
              </div>
              <div className={styles.moreOptions}>⋮</div>
            </div>
            <p className={styles.reviewText}>{review.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewPage;
