import React, { useState, useEffect } from "react";
import styles from "./Popup.module.css";
import { useSelector, useDispatch } from "react-redux";
import { getCartProducts, removeFromCart } from "../../redux/reducers/cartSlice";
import { Link, useRouteError,useNavigate } from "react-router-dom";
import { Close44 } from "../../Svg";


const Popup = ({ isOpen, onClose }) => {

  const dispatch = useDispatch();
  const nav = useNavigate()

  useEffect(() => {
    dispatch(getCartProducts());
  }, [dispatch]);

  const cartData = useSelector((state) => state.cart);

  // Currency conversion
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  };
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/Category Products');
  };
  const handleClick = () => {
    handleNavigation('/Category Products');
    onClose();
  };
  

  if (!isOpen) return null;

  const subtotal = cartData?.cartData?.reduce(
    (acc, item) =>
      acc + (item?.item?.salePrice || 0) * (item?.count || 0) * rate,
    0
  );

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <div className={styles.popupHeader}>
          <h2 className={styles.heading}>My Cart</h2>
          <button className={styles.closeButton} onClick={onClose}>
          {/* <img src={require("../../assets/images/Vector44.png")} alt="Close" style={{ width: "25px", height: "25px" }} /> */}
          <Close44/>
          </button>
        </div>

        <div className={styles.cartItems}>
          {cartData?.cartData?.length > 0 ? (
            <>
              {cartData.cartData.map((item) => (
                <div className={styles.cartItem} key={item._id}>
                  <img
                    src={item?.item?.featuredImage}
                    alt={item?.item?.name}
                    className={styles.itemImage}
                  />
                  <div className={styles.itemDetails}>
                    <p className={styles.itemTitle}>{item?.item?.name}</p>
                    <p className={styles.itemTitle1}>
                      {item.count} X{" "}
                      <span className={styles.itemPrice}>
                        {currencySymbol} {(item?.item?.salePrice * rate).toFixed(2)}
                      </span>
                    </p>
                    <p className={styles.variation}>
                      {item?.color}, {item?.size}
                    </p>
                  </div>
                  <button
                    className={styles.removeItemButton}
                    onClick={() => handleRemoveItem(item)}
                  >
                        <Close44/>
                  </button>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.message}>
            <p  className={styles.centertext}>No products are added yet!</p>
            </div>
          )}
        </div>

        {cartData?.cartData?.length > 0 && (
          <>
            <div className={styles.cartSummary}>
              <div className={styles.carttotal}>
                <p className={styles.subtotal}>Subtotal</p>
                <p className={styles.totalPrice}>
                  {currencySymbol} {subtotal.toFixed(2)}
                </p>
              </div>
              <p className={styles.disclaimer}>
                Taxes, Discounts, and shipping calculated at checkout
              </p>
            </div>

            <div className={styles.popupFooter}>
          <Link to="/Cart">
            <button onClick={onClose} className={styles.viewCartButton}>
              View Cart
            </button>
          </Link>
        </div>
          </>
        )}
       
        <div className={styles.popupFooter}>
        {
          cartData?.cartData?.length === 0 &&
          <hr className={styles.modeleline}></hr>
        }
              <button className={styles.shopMoreButton} onClick={() => {
                nav("/Category Products/")
                onClose()
              }}
                >Shop More</button>
            </div>
      </div>
      
    </div>
  );
};

export default Popup;
