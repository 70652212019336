import React from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const spanStyles = {
    fontWeight: 400, // Make the text bold
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Privacy Policy</h2>
      <p className={styles.text}>
      This policy applies to the website http://misseymoppet.com/ and to the business symbolized 
therein. The company recognizes the importance of maintaining your privacy. We value your 
privacy and appreciate your trust in us. This applies to current and former visitors to our 
website and app, and to all our online customers.
      
      </p>
      <p className={styles.text}>
      The website is a property Dhaatri Naturals Pvt Ltd , an Indian company registered under the 
Companies Act, 2013 having its registered office at 181/18, 4th floor, 5th main,4th block, 
Jayanagar, Bengaluru, Karnataka 560011. 
      </p>

      <h3 className={styles.heading2}>Information We Collect </h3>
   
    
      <ul>
  <li>  <span className={styles.text}>
      We collect information primarily to provide better services to all of our customers. 
      </span></li>
  <li><span className={styles.text}>We collect the following information from you when you use or signup on our website: Name, Address, Mail ID, Phone Number, IP Address</span></li>
  <li><span className={styles.text}>When you visit our Site, some information is automatically collected. This may include information such as the Operating Systems (OS) running on your device, Internet Protocol (IP) address, access times, browser type, and language, and the website you visited before our Site. We also collect information about how you use Our products or services.</span></li>

  <li><span className={styles.text}>We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc…</span></li>


  <li><span className={styles.text}>The full Uniform Resource Locators (URL) clickstream to, through, and from our website (including date and time); cookie number; products and/or content you viewed or searched for; page response times; download errors; length of visits to certain pages; page interaction information (such as scrolling, clicks, and mouse-overs).</span></li>
  <li><span className={styles.text}>We will retain your information as long as we require this to provide you with the goods and services and for such a period as mandated by the concerned laws.</span></li>
  <li><span className={styles.text}> If you opt to receive marketing correspondence from us, subscribe to our mailing list or newsletters, enter into any of our competitions or provide us with your details at networking events, we may use your personal data for our legitimate interests in order to provide you with details about our goods, services, business updates, and events.</span></li>
</ul>

<h3 className={styles.heading2}>How We Use Information </h3>


<li><span className={styles.text}>We use the information we collect primarily to provide, maintain, protect and improve our current products and services.</span></li>
<li><span className={styles.text}>We use the information collected through this website as described in this policy and we may use your information to:</span></li>  

<ul>  
      <p className={styles.text1}>
    <span  className={styles.text2}>  1 .</span> We use the information we collect primarily to provide, maintain, protect and improve our current products and services.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  2 .</span> Understand and enhance your experience using our Site, products, and services.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  3 .</span> Personalize our products or services and make recommendations.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  4 .</span> Provide and deliver products and services you request.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  5 .</span> Process, manage, complete, and account for transactions.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  6 .</span> Provide customer support and respond to your requests, comments, and inquiries.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  7 .</span> Create and manage the online accounts you manage on our website.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  8 .</span> Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  9 .</span>Communicate with you about promotions, upcoming events, and news about products and services.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  10 .</span> 10.We may process your personal information without your knowledge or consent where required by applicable law or regulation for the purposes of verification of identity or for prevention, detection, or investigation, including of cyber incidents, prosecution, and punishment of offenses.
      </p>
      <p className={styles.text1}>
    <span  className={styles.text2}>  11 .</span> 11.Protect, investigate and deter against fraudulent, unauthorized, or illegal activity.
      </p>
      </ul> 
      <h3 className={styles.heading2}>Cookie Policy</h3>
      <p className={styles.text}>
      A cookie is a small text file that we place on your mobile device to enable various features of Missy And Moppet. Cookies are used to store user preferences and to track user trends, so as to enhance your interactive experience and sincerely improve our services. You can change your cookie settings to accept or not accept cookies in your browser settings. If you do accept a ‘cookie’, you hereby agree to our use of any personal information collected by us using that cookie.
      </p>
      <h3 className={styles.heading2}>Third-Party</h3>
      <p className={styles.text}>
      At times we may collaborate with third parties, brand owners, or other partners and make available certain offers, events, or special schemes. In such instances, your personal information may be shared with such third parties and/or may become available to them or be disclosed to them, such third parties may have their own applicable privacy rules and we shall not be liable for the use or misuse of your information by such third parties.
      </p>
      <h3 className={styles.heading1}>WAYS OF COLLECTING INFORMATION</h3>
      <h3 className={styles.heading2}>Directly from the customer</h3>
      <p className={styles.text}>
      We collect information directly from you when you register or buy products. We also collect information if you post a comment on our websites or ask us a question through phone or email.
      </p>
      <h3 className={styles.heading2}>Passively from the customer</h3>
     

      <p className={styles.text}>
      We use tracking tools like Google Analytics, Google Webmaster, browser cookies, and web beacons for collecting information about your usage of our website.
      </p>
      <h3 className={styles.heading2}>Information Sourced from Third-Parties</h3>
      <p className={styles.text}>
        For example, if you use an integrated social media feature on our websites, the third-party social media site will give us certain information about you. This could include your name and email address.
      </p>

      {/* Use of your personal information */}
      <h3 className={styles.heading2}>Use of Your Personal Information</h3>
      <p className={styles.text}>We use your information to:</p>
      <ul>
        <li>Contact you for confirmation of a purchase on our website or for other promotional purposes.</li>
        <li>Respond to your requests or questions, such as confirming your registration for an event or contest.</li>
        <li>Analyze site trends and customer interests to improve our website and products, sometimes combining this data with information from third parties.</li>
        <li>Ensure the security of our company, our customers, or our websites.</li>
        <li>Send marketing communications regarding special promotions, offers, new features, or products—either our own or those from third parties we think you might find interesting.</li>
        <li>Send transactional communications, such as an invitation to enroll in our newsletter or for discounts and offers, as otherwise permitted by law.</li>
      </ul>

      {/* Sharing of information with third-parties */}
      <h3 className={styles.heading2}>Sharing of Information with Third-Parties</h3>
      <p className={styles.text}>We will share information:</p>
      <ul>
        <li>With third parties who perform services on our behalf, such as vendors managing our registration process or payment processing.</li>
        <li>With event organizers or other parties fulfilling purchase obligations, who may use your information as described in their privacy policies.</li>
        <li>To comply with legal obligations or protect our rights, such as responding to a court order, government request, or during fraud investigations.</li>
        <li>With any successors to our business; if part of our business is sold, customer data may be included as part of the transaction.</li>
        <li>As otherwise specified to you at the time of information collection.</li>
      </ul>

      {/* Additional Sections */}
      <h3 className={styles.heading2}>Email Opt-Out</h3>
      <p className={styles.text}>
        To stop receiving promotional emails, please email unsubscribe at info@misseymoppet.com. It may take about ten days to process your request. Even if you opt-out of marketing messages, you will still receive transactional messages regarding your purchases.
      </p>

      <h3 className={styles.heading2}>Third-Party Sites</h3>
      <p className={styles.text}>
        If you click on links to third-party websites, you may be taken to sites we do not control. This policy does not apply to the privacy practices of those websites. Please review the privacy policy of each site.
      </p>

      <h3 className={styles.heading2}>Grievance Officer</h3>
      <p className={styles.text}>
        In accordance with the Information Technology Act 2000 and rules made thereunder, contact details for the Grievance Officer are provided below:
      </p>
      <p className={styles.text}>
        Name: Anushree Nambiar<br />
        Phone: +91-9148884999<br />
        Email: info@misseymoppet.com
      </p>
      
      <h3 className={styles.heading2}>Updates to This Policy</h3>
      <p className={styles.text}>
        This Privacy Policy was last updated on Today. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. Please check our site periodically for updates.
      </p>

      <h3 className={styles.heading2}>Jurisdiction</h3>
      <p className={styles.text}>
        By visiting the website, you agree that your visit and any dispute over privacy are subject to this policy and the website’s terms of use. Disputes arising under this policy shall be governed by the laws of India.
      </p>
     
    </div>
  );
};

export default PrivacyPolicy;
