import {createAsyncThunk , createSlice} from "@reduxjs/toolkit"
import api from "../../utils/Api"
import toast from "../../utils/toast"


const initialState = {
    addressData:[],
    isLoading:false,
    error: null,
}


export const addNewAddress = createAsyncThunk("addNewAddress" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.addNewAddress(body);
  

        if (status === 200) {
            console.log("addressData",data.data.data);
            
           dispatch(setAddress(data.data.data));
            

        }
        
    } catch (error) {
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const getAllAddress = createAsyncThunk("getAllAddress" , async (body , {rejectWithValue , dispatch}) => {
    try {

        dispatch(
            setData({
              name: "isLoading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );

        const { data, status } = await api.getAllAddress(body);
  

        if (status === 200) {

            dispatch(
                setData({
                  name: "isLoading",
                  value: false,
                })
              );

            console.log("addressData",data.data.data);
            
            dispatch(setAddress(data.data.data));
        }
        
    } catch (error) {

        
        dispatch(
            setData({
              name: "isLoading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
          );

        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const deleteAddress = createAsyncThunk("deleteAddress" , async (addressId , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.deleteAddress(addressId);
  
        if (status === 200) {
            
            dispatch(setAddress(data.data.data));
        }
        
    } catch (error) {
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const editAddress = createAsyncThunk("editAddress" , async ({addressId,...body} , {rejectWithValue , dispatch}) => {
    try {
        console.log('hi')
        const { data, status } = await api.editAddress(addressId,body);
        console.log('API Response:', data, 'Status:', status);

        console.log("addressId",addressId)
        console.log("body",body)
  
        if (status === 200) {
            toast.success("Address Edited")
            dispatch(setAddress(data.data.data));
        }
        
    } catch (error) {
        console.log(error)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})




const addressSlice = createSlice({
    name:"address",
    initialState,
    reducers:{
       setAddress:(state,action) => {
        state.addressData = action.payload;
       },
       setData: (state, action) => {
        state[action.payload.name] = action.payload.value;
      },
    }
})


export const {setAddress,setData} = addressSlice.actions
export default addressSlice.reducer



