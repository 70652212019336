import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/Api";
import toast from "../../utils/toast";

const initialState = {
  allProducts:[],
  productData: [],
  pagination: {},
  fromSearch: false,
  singleProduct: null,
  averageStar: null,
  similarProducts: [],
  reviews:[],
  filterOptions: {},
  loading: false,
  error: null,
  isMobile: null
};

export const getAllProducts = createAsyncThunk(
  "getAllProducts",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );

      const { data, status } = await api.fetchProducts();

      if (status === 200) {
        console.log("dataa,d",data.data.data)
        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );
        dispatch(setAllProducts(data?.data?.data));
        dispatch(setPagination(data?.data?.pagination));
      }
    } catch (error) {
      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getAProductById = createAsyncThunk(
  "getAProductById",
  async (productId, { rejectWithValue, dispatch }) => {
    try {

      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );
      const { data, status } = await api.getProductById(productId);

      console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaafd", data);
      if (status === 200) {

        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );

        dispatch(setSingleProduct(data?.data?.data?.product));
        dispatch(setAverageStar(data?.data?.data?.averageRating));
      }
    } catch (error) {

      
      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
    );

    toast.error(error.response.data.message )
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getSimilarProducts = createAsyncThunk(
  "getSimilarProducts",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.getSimilarProducts(productId);

      if (status === 200) {
        console.log("data", data);

        dispatch(setSimilarProducts(data?.data?.data));
      }
    } catch (error) {
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const searchOrFilterProduct = createAsyncThunk(
  "searchOrFilterProduct",
  async (filters, { rejectWithValue, dispatch }) => {
    try {
        dispatch(
            setData({
              name: "loading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );

          console.log('first',filters)

        

      const queryParams = new URLSearchParams(filters).toString();

      const { data, status } = await api.searchOrFilterProduct(queryParams);

    

      if (status === 200) {
        // console.log("filter data",data)

        if(filters.isMobile){
          dispatch(setIsMobile(true) )
        }

        dispatch(
            setData({
              name: "loading",
              value: false,
            })
          );

        console.log("data", data);

        dispatch(setProducts(data?.data?.products));
      }
      dispatch(setFilters(data?.data));
      dispatch(setPagination(data?.data?.pagination));
    } catch (error) {

        dispatch(
            setData({
              name: "loading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
        );
      console.log("filterError", error);
      dispatch(setProducts([]));
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const addViewCount = createAsyncThunk(
  "addViewCount",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.addViewCount(productId);

      if (status === 200) {
        console.log("data", data);
        return;
      }
    } catch (error) {
      console.log("filterError", error);
      dispatch(setProducts([]));
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const sortProducts = createAsyncThunk(
  "sortProducts",
  async (soryBy, { rejectWithValue, dispatch }) => {
    try {

        dispatch(
            setData({
              name: "loading",
              value: true,
            })
          );
          dispatch(
            setData({
              name: "error",
              value: null,
            })
          );
      const { data, status } = await api.sortProducts(soryBy);

      if (status === 200) {
        dispatch(
            setData({
              name: "loading",
              value: false,
            })
          );

        console.log(
          "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
          data?.data?.data
        );

        dispatch(setProducts(data?.data?.data));
      }
    } catch (error) {
        dispatch(
            setData({
              name: "loading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
        );
        
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);



export const getAllReviews = createAsyncThunk(
  "getAllReviews",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.getAllReviews(productId);

      if (status === 200) {
        console.log("data", data);
        dispatch(setReviews(data?.data));
        return;
      }
    } catch (error) {
      console.log("filterError", error);
    
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);





export const subscribeToOutofstockProducts = createAsyncThunk(
  "subscribeToOutofstockProducts",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.subscribeToOutofstockProducts(productId);

      if (status === 200) {
        console.log("data", data);
        toast.success("We Will Notify You When Product Is Back Online")
        return;
      }
    } catch (error) {
      console.log("error", error);
    toast.error("Please Login")
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);


const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.productData = action.payload;
    },
    setIsMobile : (state,action) => {
      state.isMobile = action.payload;
    },
    setFromSearch : (state,action) => {
      state.fromSearch = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action.payload;
    },
    setAverageStar: (state, action) => {
      state.averageStar = action.payload;
    },
    setSimilarProducts: (state, action) => {
      state.similarProducts = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setFilters: (state, action) => {
      state.filterOptions = action.payload;
    },
    setData: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    setAllProducts: (state,action) => {
      state.allProducts = action.payload
    }
  },
});

export const {
  setProducts,
  setSingleProduct,
  setData,
  setSimilarProducts,
  setFilters,
  setAverageStar,
  setPagination,
  setIsMobile,
  setReviews,
  setAllProducts,
  setFromSearch
} = productSlice.actions;
export default productSlice.reducer;
