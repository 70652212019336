import React, { useEffect, useState } from "react";
import styles from "./orderhistory.module.css";
import { Paginationleft, Paginationright, Star17 } from "../../../Svg";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderHistory,
  setSelectedOrder,
} from "../../../redux/reducers/orderSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import ErrorPage from "../../../components/errorpage/ErrorPage";
import ProfileMenu from "../../dashcomponents/profilemenu/ProfileMenu";
import { FaChevronRight } from "react-icons/fa";
import EmptyPage from "../../../components/emptyPage";


const OrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get rate, currency, and symbol from localStorage
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  const { orders, pagination, isLoading } = useSelector((state) => state.order);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pagination?.currentPage);
    setTotalPages(pagination?.totalPages);
    setTotalItems(pagination?.totalItems);
    setItemsPerPage(pagination?.limit || 10);
  }, [pagination]);

  useEffect(() => {
    dispatch(getOrderHistory({ page: currentPage }));
  }, [dispatch, currentPage]);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const calculateShowingRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, totalItems);
    return { start, end };
  };

  const { start, end } = calculateShowingRange();

  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(
          1,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          1,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <>
    <div style={{display:"flex" }} className={styles.sidebar}>
    <ProfileMenu selected={"Order history"}/>
      <div className={styles.myprofile}>
        <div className={styles.orderContainer}>
          <div className={styles.orderheader}>
            <h2>Order History</h2>
          </div>

          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 20,
              }}
            >
              <CircularProgress />
            </Box>
          ) : orders?.data?.length > 0 ? (
            <>
              <div className={styles.orderContent}>
                <div className={styles.orderhistory}>
                  <table>
                    <thead>
                      <tr>
                        <th>ORDER ID</th>
                        <th>DATE</th>
                        <th>TOTAL</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.data?.map((order, index) => (
                        <tr key={index}>
                          <td>{order?.order_id}</td>
                          <td>
                            {moment(order?.createdAt).format("D MMMM, YYYY")}
                          </td>
                          <td>
                            {currency === "USD" || order?.currency === "USD"
                              ? `${order?.currency} ${order?.grandTotal.toFixed(2)}`
                              : `${currency} ${(order?.grandTotal * rate).toFixed(2)}`}
                            ({order?.order_items?.length} products)
                          </td>
                          <td>{order?.status}</td>
                          <td>
                            <a
                              onClick={() => {
                                navigate(`/Order Details/${order?._id}`)
                                dispatch(setSelectedOrder(order?._id));
                              }}
                            >
                              View Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={styles.pagination}>
                <button
                  onClick={() => onPageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={styles.paginationButton1}
                >
                  <Paginationleft />
                </button>

                {pageNumbers.map((number) => (
                  <button
                    onClick={() => goToPage(number)}
                    className={`${styles.paginationButton} ${
                      number === currentPage ? styles.active : ""
                    }`}
                    key={number}
                  >
                    {number}
                  </button>
                ))}

                <button
                  onClick={() => onPageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={styles.paginationButton1}
                >
                  <Paginationright />
                </button>
              </div>
            </>
          ) : (
            <EmptyPage content={"No Order History Found"} button={"REFRESH"} />
          )}
        </div>
      </div>
      
    </div>
    <div className={styles.myprofilemobile}> 
    <div className={styles.orderHistoryContainer}>
    <h2 className={styles.title}>Order History</h2>
    {orders?.data?.map((order, index) => (
      <div key={index} className={styles.orderItem}>
        <div className={styles.iconContainer}>
          {/* Replace with your own icon or SVG */}
          <img src={require('../../../assets/images/Group2345.png')} className={styles.boximg} />
        </div>
        <div className={styles.orderDetails}>
          <p className={styles.orderId}>Order #{order?.order_id}</p>
          <p className={styles.orderDate}>Placed on  {moment(order?.createdAt).format("D MMMM, YYYY")}</p>
          <p className={styles.orderInfo}>
            Items: <span className={styles.total}>{order?.order_items?.length} &nbsp;</span> Total: <span className={styles.total}> {currency === "USD" || order?.currency === "USD"
                            ? `${order?.currency} ${order?.grandTotal.toFixed(2)}`
                            : `${currency} ${(order?.grandTotal * rate).toFixed(2)}`}</span>
          </p>
          <p className={`${styles.status} ${order.status === 'On the way' ? styles.onTheWay : ''}`}>
          {order?.status}
          </p>
        </div>
        <div className={styles.arrowIcon} onClick={() => {
                                navigate(`/Order Details/${order?._id}`)
                                dispatch(setSelectedOrder(order?._id));
                              }}>
          <Star17/>
        </div>
      </div>
      ))}
  </div>
  </div>
 </>
  );
};

export default OrderHistory;
