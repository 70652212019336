import React from 'react';
import styles from './addressbody.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addNewAddress, editAddress } from '../../../redux/reducers/addressSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddressBody = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const addressData = location?.state?.addressData[0];

  const initialValues = {
    firstName: addressData?.firstName || '',
    lastName: addressData?.lastName || '',
    phone: addressData?.phone || '',
    houseNumber: addressData?.streetAddress?.split(',')[0] || '',
    addressLine2: addressData?.streetAddress?.split(',')[1] || '',
    companyName: addressData?.companyName || '',
    city: addressData?.city || '',
    zip: addressData?.zip || '',
    state: addressData?.state || '',
    country: addressData?.country || '',
    isDefault: addressData?.isDefault || false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    houseNumber:Yup.string().required("House address is requires"),
    phone: Yup.string().matches(/^\d{10}$/, 'Phone must be 10 digits').required('Phone is required'),
    zip: Yup.string().matches(/^\d{6}$/, 'Zip code must be 6 digits').required('Zip code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let resultAction;
      if (addressData) {
        // If editing, update address
        resultAction = await dispatch(editAddress({ addressId: addressData._id, ...values }));
      } else {
        // If adding a new address
        resultAction = await dispatch(addNewAddress({
          ...values,
          streetAddress: values.houseNumber + ', ' + values.addressLine2,
        }));
      }
      
      unwrapResult(resultAction);
      navigate('/My Address');
    },
  });

  
  const handleZipChange = async (e) => {
    const { value } = e.target;
    formik.handleChange(e);

    if (value.length === 6) {
      try {
        const response = await axios.get(`https://api.postalpincode.in/pincode/${value}`);
        const postData = response.data[0];
        if (postData.Status === "Success" && postData.PostOffice.length > 0) {
          const { State, District, Country, Name } = postData.PostOffice[0];
          formik.setValues((prevValues) => ({
            ...prevValues,
            state: State,
            city: Name,
            country: Country,
          }));
        } else {
          formik.setFieldError('zip', 'Invalid pincode or no data found');
        }
      } catch (error) {
        console.error("Error fetching pincode data:", error);
      }
    }
  };

  return (
    <div className={styles.profilebodyContainer}>
      <div className={styles.profileheader}>
        <h2>{addressData ? 'Edit Address' : 'Add Address'}</h2>
        <button className={styles.editButton} onClick={() => navigate('/My Address')}>
          Back to List
        </button>
      </div>
      <div className={styles.profilebodyContent}>
        <form className={styles.profileform} onSubmit={formik.handleSubmit}>
          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="First name *"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <p className={styles.errorText}>{formik.errors.firstName}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Last name *"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <p className={styles.errorText}>{formik.errors.lastName}</p>
              )}
            </div>
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Phone *"
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className={styles.errorText}>{formik.errors.phone}</p>
            )}
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="text"
              name="houseNumber"
              value={formik.values.houseNumber}
              onChange={formik.handleChange}
              placeholder="House number and street name"
            />
               {formik.touched.houseNumber && formik.errors.houseNumber && (
                <p className={styles.errorText}>{formik.errors.houseNumber}</p>
              )}
          </div>

          <div className={styles.profileformGroup}>
            <input
              type="text"
              name="addressLine2"
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              placeholder="Apartment, suite, unit, etc. (optional)"
            />
          </div>

          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="City *"
              />
              {formik.touched.city && formik.errors.city && (
                <p className={styles.errorText}>{formik.errors.city}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="zip"
                value={formik.values.zip}
                onChange={handleZipChange}
                onBlur={formik.handleBlur}
                placeholder="Zip *"
              />
              {formik.touched.zip && formik.errors.zip && (
                <p className={styles.errorText}>{formik.errors.zip}</p>
              )}
            </div>
          </div>

          <div className={styles.profilerow}>
            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="State *"
              />
              {formik.touched.state && formik.errors.state && (
                <p className={styles.errorText}>{formik.errors.state}</p>
              )}
            </div>

            <div className={styles.profileformGroup}>
              <input
                type="text"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Country *"
              />
              {formik.touched.country && formik.errors.country && (
                <p className={styles.errorText}>{formik.errors.country}</p>
              )}
            </div>
          </div>

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              name="isDefault"
              checked={formik.values.isDefault}
              onChange={formik.handleChange}
            />
            <label>Make this the default address</label>
          </div>

          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={() => navigate('/myaddress')}
            >
              Cancel
            </button>
            <button type="submit" className={styles.saveButton}>
              {addressData ? 'Update Address' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressBody;
