import React, { useEffect } from "react";
import styles from "./thankyoupage.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLastOrder } from "../../redux/reducers/orderSlice";

const Thankyoupage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currency = localStorage.getItem("currency")
  const rate = parseFloat(localStorage.getItem("rate")) || 1;

  const currencySymbol = currency === "USD" ? "$" : "₹"

  console.log(currency)

  useEffect(() => {
    dispatch(getLastOrder());
  }, []);

  const {lastOrder} = useSelector(state => state.order)

  const products = lastOrder?.data?.order_items  || [];

  console.log("products",products)


  console.log("aaaaaaa",lastOrder)

  return (
    <div className={styles.container}>
      <h1 className={styles.thankYou}>Thank you! 🎉</h1>
      <h2 className={styles.orderReceived}>Your order has been received</h2>
      <p className={styles.subtext}>Fashion That Makes You Say 'Aww!'</p>
      <div className={styles.productsimg}>
        <div className={styles.products}>
           {products.map((product, index) => (
            <div key={product._id} className={styles.product}>
              <img
                src={product.productId?.featuredImage || require("../../assets/images/npm1.png")}
                alt={product.name}
                className={styles.mainImg_1}
              />
              <div className={styles.productNumber}>{product?.units}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.orderDetails}>
        <div className={styles.code}>
          <p className={styles.code1}>Order code:</p>
          <p className={styles.code2}>{lastOrder?.data?.order_id}order_id
 </p>{" "}
        </div>
        <div className={styles.code}>
          <p className={styles.code1}>Date:</p>
          <p className={styles.code2}>{lastOrder?.data?.order_date}</p>
        </div>
        <div className={styles.code}>
          <p className={styles.code1}>Total:</p>
          <p className={styles.code2}>{lastOrder?.data?.currency === "USD" ? "$" : "₹"} {lastOrder?.data?.grandTotal}</p>
        </div>
        <div className={styles.code}>
          <p className={styles.code1}>Payment method:</p>
          <p className={styles.code2}>{lastOrder?.data?.payment_method}</p>
        </div>
      </div>

      <button
        className={styles.continueShopping}
        onClick={() => navigate("/Category Products/")}
      >
        Continue Shopping
      </button>
    </div>
  );
};

export default Thankyoupage;
