import React from 'react';
import styles from './changePassword.module.css';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';

const ChangePassword = () => {
    return (
        <div className={styles.myprofile}>
            <div className={styles.promenu}>
            <ProfileMenu />
            </div>
        <div className={styles.changePassContainer}>
            <div className={styles.changePassheader}>
                <h2>Change Password</h2>
                <button className={styles.editButton} /* onClick={handleEdit} */>
                    {/* {isEditing ? 'Save' : 'Edit changePass'} */}
                    Back to profile
                </button>
            </div>
            <div className={styles.changePassbodyContent}>
                <div className={styles.changePassbodyDetails}>
                    <label htmlFor="password">Current Password</label>
                    <input
                        type="password"
                        id="currentpassword"
                        name="currentpassword"
                        value="Password"
                        /*  disabled={!isEditing} */
                    />

                    <label htmlFor="password">New Password</label>
                    <input
                        type="password"
                        id="newpassword"
                        name="newpassword"
                        value="Password"
                    /*  disabled={!isEditing} */
                    />

                    <label htmlFor="password">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmpassword"
                        name="confirmpassword"
                        value="Password"
                        /*  disabled={!isEditing} */
                    />

                    <button className={styles.changePass}>Update Password</button>
                </div>

                {/* <div className={styles.changePassbodyImage}>
                    <img
                        src="https://via.placeholder.com/150"
                        alt="User Avatar"
                        className={styles.avatar}
                    />
                    <button className={styles.changeImageButton}>Change Image</button>
                </div> */}
            </div>
        </div>
    </div>
    );
};

export default ChangePassword;
