import React from 'react'
import styles from "./ourPinky.module.css"
import { useNavigate } from 'react-router-dom'
const OurPinky = () => {

  const navigate = useNavigate()
  return (
<div className={styles.container}>
<div className={styles.section}>
<div className={styles.left}>
<div className={styles.maincontainer}>
            <img src={require('../../assets/images/npm1.png')} className={styles.mainImg_1} />
          </div>
        </div>
        <div className={styles.right}>
          <h2 className={styles.heading}>Our Pinky Promise</h2>
          <p className={styles.head}>At Missy & Moppets, We’re Committed To Quality For You + Your Baby. And We Promise To Be A Source Of Trusted Information And Inspiration For All Moms. Good Things Begin Here.
          </p>
          <button className={styles.button_2} onClick={() => navigate("/Category Products/")}>Shop Our Products</button>
        </div>

      </div>

    </div>
  )
}

export default OurPinky