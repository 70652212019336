import React from 'react'
import styles from './addSlides.module.css'
const AddSliders = () => {
  return (
    <div className={styles.container}>
   
      <div className={styles.wrapper}>
                <div className={styles.slider}>
            <div className={styles.slidetrack}>
                        <div  className={styles.slide}>
                          <img className={styles.hide} src="./assets/images/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand2.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand3.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand4.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand5.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand2.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand3.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand4.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand5.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand2.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand3.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand4.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand5.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand2.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand3.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand4.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand5.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand2.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/3.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand4.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand5.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/brand1.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img className={styles.hide} src="./assets/images/brand2.png" alt="" />
            </div>
         </div>
        </div>
      </div>
    </div>
  )
}

export default AddSliders
