import { configureStore } from "@reduxjs/toolkit";
import productSlice from "./reducers/productSlice";
import cartSlice from "./reducers/cartSlice";
import userSlice from "./reducers/userSlice";
import orderSlice from "./reducers/orderSlice"
import addressSlice from "./reducers/addressSlice";
import wishlistSlice from "./reducers/wishlistSlice";



 const store = configureStore({
    reducer:{
        products:productSlice,
        cart:cartSlice,
        user:userSlice,
        order:orderSlice,
        address:addressSlice,
        wishlist:wishlistSlice
    }
})

export default store