import React, { useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import HeaderTop from "./smallcomponents/headerTop/HeaderTop";
import Categorypage from "./components/categorypage/Categorypage";
import Productpage from "./components/productpage/Productpage";
import MobileFilter from "./smallcomponents/mobileFilter/MobileFilter";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs"; // Import Breadcrumbs component
import Signup from "./auths/signup/Signup";
import Otp from "./auths/otp/Otp";
import Login from "./auths/login/Login";
import CartPage from "./components/cartpage/CartPage";
import MyCart from "./smallcomponents/mycart/MyCart";

import { getAllProducts } from "../src/redux/reducers/productSlice";
import { useDispatch } from "react-redux";
import Myprofile from "./dashboard/dashpages/myprofile/Myprofile";
import ChangePassword from "./dashboard/dashpages/changePassword/ChangePassword";
import MyprofileDetails from "./dashboard/dashpages/myprofiledetails/MyprofileDetails";
import OrderHistory from "./dashboard/dashpages/orderhistory/OrderHistory";
import OrderDetails from "./dashboard/dashpages/orderdetails/OrderDetails";
import ReturnExchange from "./dashboard/dashpages/returnexchange/ReturnExchange";
import EmptyAddress from "./dashboard/dashpages/emptyaddress/EmptyAddress";
import MyAddress from "./dashboard/dashpages/myaddress/MyAddress";
import AddAddress from "./dashboard/dashpages/addaddress/AddAddress";
import EmptyCard from "./dashboard/dashpages/emptycard/EmptyCard";
import AddCard from "./dashboard/dashpages/addcard/AddCard";
import Thankyou from "./emailtemplates/thankyou/Thankyou";
import Welcome from "./emailtemplates/wlecome/Welcome";
import PaymentCard from "./dashboard/dashpages/paymentcard/PaymentCard";
import Checkout from "./components/checkout/Checkout";
import Wishlistpage from "./components/wishlistpage/Wishlistpage";
import Contact from "./components/contact/Contact";
import ErrorPage from "./components/errorpage/ErrorPage";
import Thankyoupage from "./components/thankyou/Thankyoupage";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ProfileMenu from "./dashboard/dashcomponents/profilemenu/ProfileMenu";
import FAQ from "./components/faq/FAQ";
import PrivacyPolicy from "./smallcomponents/privacyPolicy/PrivacyPolicy";
import TermsCondition from "./smallcomponents/termsCondition/TermsCondition";
import ReviewPage from "./smallcomponents/Reviewpage/ReviewPage";

import ProtectedRoute from "./utils/protectedRoute";
import CartPageDashbord from "./components/cartpage/CartpageDashbord";

const initialOptions = {
  "client-id": "AfRypgBUiphUs_bCNBgDQL7C4RJFK89HNlrYKOf1a7NbbkkvrjJcPNdKemQSJT85ocmtmaIv5OAMKuKf",
  components: "buttons",
  currency: "USD",
  intent: "capture",
};

const App = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllProducts());
  // }, []);
  return (
   
    <BrowserRouter>
     <PayPalScriptProvider options={initialOptions}>
      <AppContent />
      </PayPalScriptProvider>
    </BrowserRouter>
  
  );
};

const AppContent = () => {
  const location = useLocation();
  const hideComponents = ["/login", "/otp", "/signup", "/mobile-filter"];

  return (
    <>
         <ToastContainer
          position="top-right"
          autoClose={2000}
          theme='dark'
        >
        </ToastContainer>
      {location.pathname !== "/mobile-filter" &&
        <HeaderTop />}
      {
      // location.pathname !== "/mobile-filter" &&
        location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/otp" && <Header />}

      {/* Breadcrumbs visible on every page except /mobile-filter and home page */}
      {location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/otp" &&
        location.pathname !== "/home" && 
        location.pathname !== "/mobile-filter" && <Breadcrumbs />}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/Category Products/" element={<Categorypage />} />
        <Route path="/mobile-filter" element={<MobileFilter />} />
        <Route path="/Shopping Cart" element={<ProtectedRoute><CartPage /></ProtectedRoute>} />
        <Route path="/Cart" element={<CartPageDashbord />} />
        <Route path="/Wishlist" element={<ProtectedRoute><Wishlistpage /></ProtectedRoute>} />
        <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
        <Route path="/thank" element={<ProtectedRoute><Thankyoupage /></ProtectedRoute>} />
        <Route path="/Category Products/Product details/:id" element={<Productpage />} />
        <Route path="/Edit Profile" element={<ProtectedRoute><Myprofile /></ProtectedRoute>} />
        <Route path="/changepassword" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
        <Route path="/usermenu" element={<ProtectedRoute><ProfileMenu /></ProtectedRoute>} />
        <Route path="/My Profile" element={<ProtectedRoute><MyprofileDetails /></ProtectedRoute>} />
        <Route path="/Order history" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>} />
        <Route path="/Order Details/:id" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
        <Route path="/returnexchange/:id" element={<ProtectedRoute><ReturnExchange /></ProtectedRoute>} />
        <Route path="/emptyaddress" element={<ProtectedRoute><EmptyAddress /></ProtectedRoute>} />
        <Route path="/My Address" element={<ProtectedRoute><MyAddress /></ProtectedRoute>} />
        <Route path="/addaddress" element={<ProtectedRoute><AddAddress /></ProtectedRoute>} />
        <Route path="/emptycard" element={<ProtectedRoute><EmptyCard /></ProtectedRoute>} />
        <Route path="/paymentcard" element={<ProtectedRoute><PaymentCard /></ProtectedRoute>} />
        <Route path="/addcard" element={<AddCard />} />
        <Route path="/thankyou" element={<ProtectedRoute><Thankyou /></ProtectedRoute>} />
        <Route path="/wlecome" element={<Welcome />} />
        <Route path="/Contact US" element={<Contact />} />
        {/* <Route path="/checkout" element={<Checkout />} /> */}
        <Route path="/mobile-filter" element={<MobileFilter />} />
       
        <Route path="/errorpage" element={<ErrorPage />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termscondition" element={<TermsCondition />} />
        <Route path="/thank" element={<ProtectedRoute><Thankyoupage /></ProtectedRoute>} />
        <Route path="/reviewpage" element={<ReviewPage/>} />
        <Route path="*" element={<ErrorPage content={"No Page Found"} />} />
      </Routes>

      {
      // location.pathname !== "/mobile-filter" &&
        location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/otp" && <Footer />}
    </>
  );
};

export default App;
