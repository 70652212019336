import React, { useEffect, useState } from "react";
import styles from "./MobileFilter.module.css";
import { useSelector, useDispatch } from "react-redux";
import { sortProducts, searchOrFilterProduct,setFromSearch } from "../../redux/reducers/productSlice";
import chroma from "chroma-js";
import { Navigate, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Arrow1 } from "../../Svg";

const MobileFilter = () => {
  const nav = useNavigate()
  const dispatch = useDispatch();




  const { filterOptions,fromSearch } = useSelector((state) => state.products);

  // Local state to store filters
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [selectedCategory, setSelectedCategory] = useState("Category");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [sortOrder, setSortOrder] = useState("");

  const priceRanges = [
    { range: "₹100 - ₹499", checked: false },
    { range: "₹500 - ₹999", checked: false },
    { range: "₹1000 - ₹1999", checked: false },
    { range: "₹2000 and Up", checked: false },
  ];

  const handleColorChange = (color) => {
    setSelectedColor(selectedColor === color ? "" : color);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(selectedSize === size ? "" : size);
  };
  const navigate = useNavigate();

  


  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handlePriceRangeChange = (range) => {
    if (selectedRange === range.range) {
      // Deselect if the same range is selected
      setMinPrice(0);
      setMaxPrice(10000);
      setSelectedRange(null);
    } else {
      // Set new range
      const [min, max] = range.range.split(" - ").map((price) => {
        const num = price.replace(/[^0-9]/g, "");
        return parseInt(num, 10);
      });
      setMinPrice(min || null);
      setMaxPrice(max || 1000000);
      setSelectedRange(range.range);
    }
  };

  const handleMinPriceChange = (e) => {
    setMinPrice(e.target.value);
    setSelectedRange(null);
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(e.target.value);
    setSelectedRange(null);
  };

  const handleCategoryClick = (item) => {
    setSelectedCat(selectedCat === item.name ? "" : item.name);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const filters = {
    minPrice,
    maxPrice,
    selectedCategory:selectedCat,
    selectedColor,
    selectedSize,
    isMobile:true
  };

  useEffect(() => {
    dispatch(searchOrFilterProduct(filters));
  }, [dispatch]);

 

  const handleApplyFilters = async () => {

    
 
    const resultAction = await dispatch(searchOrFilterProduct(filters));
    unwrapResult(resultAction);

    dispatch(setFromSearch(true));

    

    console.log("result action",resultAction)
    nav('/Category Products/');

    if (sortOrder) {
      dispatch(sortProducts(sortOrder));
    }
    window.scrollTo(0, 0);
  };

  const handleClear = () => {
    setMinPrice("");
    setMaxPrice("");
    setSelectedCat('');
    setSelectedColor("");
    setSelectedSize("");
    setSelectedRange(null)
  }

  console.log("minPrice", minPrice);
  console.log("maxPrice", maxPrice);
  console.log("selectedColor", selectedColor);
  console.log("selectedSize", selectedSize);
  console.log("selectedCat", selectedCat);

  return (
    <div className={styles.mobileFilterContainer}>
      <div className={styles.global}>
      <div className={styles.arrow} onClick={goBack}>
        <Arrow1 />{" "}
        </div>
        <div className={styles.glob}>
        <span className={styles.productshows}>Filter (20 products)</span>
      <span className={styles.clrear}>Clear all</span>
      </div>
      </div>
      <div className={styles.categories}>
        <div className={styles.categoriesSection}>
          <ul>
            <li
              onClick={() => setSelectedCategory("Category")}
              className={selectedCategory === "Category" ? styles.active : ""}
            >
              Categories
            </li>
            <li
              onClick={() => setSelectedCategory("Price")}
              className={selectedCategory === "Price" ? styles.active : ""}
            >
              Price
            </li>
            <li
              onClick={() => setSelectedCategory("Color")}
              className={selectedCategory === "Color" ? styles.active : ""}
            >
              Color
            </li>
            <li
              onClick={() => setSelectedCategory("Size")}
              className={selectedCategory === "Size" ? styles.active : ""}
            >
              Size
            </li>
          </ul>
        </div>

        <div className={styles.filterOptionsSection}>
          <div className={styles.header}>
            <span className={styles.All}>All {selectedCategory}</span>
            <button className={styles.clearButton} onClick={() => handleClear()}>
              Clear
            </button>
          </div>

          <ul className={styles.filterList}>
            {selectedCategory === "Category" &&
              filterOptions?.category?.map((item, index) => (
                <li key={index} className={styles.filterItem} onClick={() => handleCategoryClick(item)}>
                  <div className={styles.stand}>
                  <label className={styles.label}>
                    <input
                      type="checkbox"
                      className={styles.customCheckbox}
                      checked={selectedCat === item.name}
                      readOnly
                    />
                   <div className={styles.mainspace}>
                    <div className={styles.mess}>  {item.name}</div> <div>{item?.count}</div>
                    </div>
                  </label>
                  </div>
                </li>
              ))}

            {selectedCategory === "Price" && (
              <div className={styles.priceFilterContent}>
                {priceRanges.map((priceRange, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      checked={selectedRange === priceRange.range}
                      onChange={() => handlePriceRangeChange(priceRange)}
                    />
                    {priceRange.range}
                  </li>
                ))}
              </div>
            )}

            {selectedCategory === "Color" &&
              filterOptions?.color?.map((color, index) => {
                const colorCode = chroma(color.color).hex();
                return (
                  <li key={index} className={styles.filterItem} onClick={() => handleColorChange(color.color)}>
                    <label className={styles.label}>
                      <input
                        type="checkbox"
                        className={styles.customCheckbox}
                        checked={selectedColor === color.color}
                        readOnly
                      />
                      <span
                        className={styles.colorCircle}
                        style={{
                          backgroundColor: colorCode,
                          border: ".5px solid black",
                        }}
                      ></span>
                        <div style={{display:'flex',justifyContent:"space-between",minWidth:"25vh"}}>
                      {color.color}  <span>{color?.count}</span>
                      </div>
                    </label>
                  </li>
                );
              })}

            {selectedCategory === "Size" &&
              filterOptions?.size?.map((size, index) => (
                <li key={index} className={styles.filterItem} onClick={() => handleSizeChange(size.size)}>
                  <label className={styles.label}>
                    <input
                      type="checkbox"
                      className={styles.customCheckbox}
                      checked={selectedSize === size.size}
                      readOnly
                    />
                    <div style={{display:'flex',justifyContent:"space-between",minWidth:"25vh"}}>
                    {size.size}  <span>{size?.count}</span>
                      </div>                   
                  </label>
                </li>
              ))}
          </ul>
        </div>

        <div className={styles.filterFooter}>
          <button className={styles.cancelButton} onClick={() => nav("/Category Products/")}>
            Cancel
          </button>
          <button className={styles.applyButton} onClick={handleApplyFilters}>
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilter;
