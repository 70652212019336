import React, { useEffect } from "react";
import styles from "./wishliste.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
  moveFromWishlistToCart,
  deleteAllItemsFromWishlist,
  moveAllFromWishlistToCart
} from "../../redux/reducers/wishlistSlice";
import { Bin } from "../../Svg";
import { CircularProgress, Box } from "@mui/material";
import ErrorPage from "../../components/errorpage/ErrorPage"; // Assuming you have an ErrorPage component
import { useNavigate } from "react-router-dom";
import ProfileMenu from "../../dashboard/dashcomponents/profilemenu/ProfileMenu";
import EmptyPage from "../emptyPage";

const Wishlistpage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { wishlistData, isLoading } = useSelector((state) => state.wishlist);

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  const incrementCount = (item) => {
    const body = {
      productId: item?.item?._id,
      quantity: 1,
      countInc: true,
      size: item?.size,
      color: item?.color,
    };
    dispatch(addToWishlist(body));
  };

  const decrementCount = (item) => {
    const body = {
      productId: item?.item?._id,
      quantity: 1,
      countInc: false,
      size: item?.size,
      color: item?.color,
    };
    dispatch(addToWishlist(body));
  };

  const handleRemoveItem = (item) => {
    dispatch(deleteFromWishlist(item));
  };

  const handleClearWishlist = () => {
    dispatch(deleteAllItemsFromWishlist())
  }

  const handleMoveFromWishlistToCart = (productId) => {
    dispatch(moveFromWishlistToCart(productId));
  };

  const handleMoveAllFromWishlistToCart = (productId) => {
    dispatch(moveAllFromWishlistToCart());
  };

  



  if (!wishlistData || wishlistData.length === 0) {
    return <div style={{display:"flex",}}>
  <ProfileMenu selected={"Wishlist"}/>
  <div className={styles.Container}>
   <EmptyPage content="Your wishlist is empty!" />
   </div>
    </div>  
  }

  return (
    <div style={{display:"flex"}}>
    <ProfileMenu selected={"Wishlist"}/>
    {isLoading ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vh"
      >
        <CircularProgress />
      </Box>
    ) : (
    <div className={styles.Container}>
    <div className={styles.button_con}>
    <button className={styles.body_button} onClick={handleClearWishlist}>Clear Wishlist</button>
    <button className={styles.body_button} onClick={handleMoveAllFromWishlistToCart}>Move All To Bag</button>
      </div>
   
      <div className={styles.cartContainer}>
     
        {/* Left Side - Cart Items */}
        <div className={styles.cartItems}>
          <table className={styles.cartTable}>
            <thead>
              <tr>
                <th className={styles.heading}>Image</th>
                <th className={styles.heading}>Product</th>
                <th className={styles.heading}>Quantity</th>
                <th className={styles.heading}>Price</th>
                <th className={styles.heading}>Total</th>
                <th className={styles.heading}></th>
              </tr>
            </thead>
            <tbody>
              {wishlistData.map((item, index) => (
                <tr key={index} className={styles.productDetailes}>
                  <td>
                    <img
                      src={item?.item?.featuredImage}
                      alt={item?.item?.name}
                      className={styles.productImage}
                    />
                  </td>
                  <td className={styles.head}>{item?.item?.name}</td>
                  <td className={styles.head}>
                    <div className={styles.quantityControls}>
                      <div className={styles.Controls}>
                        <button
                          className={styles.opreter}
                          onClick={() => decrementCount(item)}
                        >
                          -
                        </button>
                        <span className={styles.num}>{item.count}</span>
                        <button
                          className={styles.opreter}
                          onClick={() => incrementCount(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className={styles.head}>
                    {currencySymbol}
                    {(item?.item?.salePrice * rate).toFixed(2)}
                  </td>
                  <td className={styles.head}>
                    {currencySymbol}
                    {(item?.item?.salePrice * item.count * rate).toFixed(2)}
                  </td>
                  <td>
                    <div className={styles.buttondelat}>
                      <button
                        className={styles.cartbutton}
                        onClick={() =>
                          handleMoveFromWishlistToCart(item?.item?._id)
                        }
                      >
                        Move to Cart
                      </button>
                      <button
                        className={styles.removeButton}
                        onClick={() => handleRemoveItem(item)}
                      >
                        <Bin />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.addMoreItems}>
            <a className={styles.more} href="/Category Products/">
              Add More Items
            </a>
          </div>
        </div>
      </div>
      <div className={styles.mobilecontainer}>
        <h2
          className={styles.mobileheading}
          onClick={() => navigate("/Category Products  ")}
        >
          Add More Items
        </h2>
        <div className={styles.mobilecontant1}>
          {wishlistData.map((item, index) => (
            <div className={styles.mobilecontant}>
              <div className={styles.mobilebox1}>
                <img
                  src={item?.item?.featuredImage}
                  alt={item?.item?.name}
                  className={styles.productImage}
                />
                <div className={styles.rast}>
                  <button
                    className={styles.removeButton}
                    onClick={() => handleRemoveItem(item)}
                  >
                    <Bin />
                  </button>
                  <h5 className={styles.head}>{item?.item?.name}</h5>
                </div>
              </div>
              <div className={styles.mobilebox}>
                <p className={styles.heading}>Price</p>
                <p className={styles.head}>
                  ₹{item?.item?.salePrice.toFixed(2)}
                </p>
              </div>
              <div className={styles.mobilebox}>
                <p className={styles.heading}>Quantity</p>
                <div className={styles.quantityControls}>
                  <div className={styles.Controls}>
                    <button
                      className={styles.opreter}
                      onClick={() => decrementCount(item)}
                    >
                      -
                    </button>
                    <span className={styles.num}>{item.count}</span>
                    <button
                      className={styles.opreter}
                      onClick={() => incrementCount(item)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.mobilebox}>
                <p className={styles.heading}>Total</p>
                <p className={styles.head}>
                  ₹{(item?.item?.salePrice * item.count).toFixed(2)}
                </p>
              </div>
              <button
                className={styles.cartbutton}
                onClick={() => handleMoveFromWishlistToCart(item?.item?._id)}
              >
                Move to Cart
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )}
    </div>
  );
};

export default Wishlistpage;
