import React from "react";
import styles from "./logout.module.css";
import { useNavigate } from "react-router-dom";

const LogoutConfirmation = ({ onClose }) => {
  const nav = useNavigate();

  const handleCancel = () => {
    onClose(); // Closes the modal
  };

  const handleLogout = () => {
    localStorage.clear("token");
    nav("/login");
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Logout!</h2>
      <p className={styles.message}>Are you sure you want to logout?</p>
      <div className={styles.buttonGroup}>
        <button className={styles.cancelButton} onClick={handleCancel}>
          Cancel
        </button>
        <button className={styles.logoutButton} onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default LogoutConfirmation;
