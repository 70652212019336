import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./testimonials.module.css";
import { Profile } from "../../Svg";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // dotsClass:{width:200},
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const testimonials = [
    {
      text: "The customer experience was exceptional from start to finish. The website is user-friendly, the checkout process was smooth, and the clothes I ordered fit perfectly.",
      name: "Emily Wilson",
    },
    {
      text: "I love the variety of styles available. The delivery was fast, and the packaging was really thoughtful. Highly recommended!",
      name: "Sophia Davis",
    },
    {
      text: "Amazing quality and service. I'll definitely shop again. Everything exceeded my expectations!",
      name: "Olivia Johnson",
    },
  ];

  return (
 
    <div className={styles.container}>
      <div className={styles.titles}>
        <h2 className={styles.heading}>Testimonials</h2>
      </div>
      <div className="testimonial">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className={styles.slide} key={index}>
              <div className={styles.section}>
                <div className={styles.sec}>
                  <h3 className={styles.text}>{testimonial.text}</h3>
                  <div className={styles.box}>
                    <div>
                    <Profile />
                    </div>
                    <div>
                    <p className={styles.tex1}>{testimonial.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>

  );
};

export default Testimonials;
