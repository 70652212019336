import React, { useState,useEffect } from "react";
import styles from "./profilebody.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../utils/Api";
import toast from "../../../utils/toast";
import OtpPopup from "../../../smallcomponents/otpPopup";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const ProfileBody = () => {
  const location = useLocation();
  const nav = useNavigate();
 


  const [profileData, setProfileData] = useState()
  const [fullName, setFullName] = useState(profileData?.name || "");
  const [email, setEmail] = useState(profileData?.email || "");
  const [phone, setPhone] = useState(profileData?.phone || "");
  const [errors, setErrors] = useState({});
  const [activeField, setActiveField] = useState(""); // Track active field ('email' or 'phone')
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  


  const nameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/;

  console.log(profileData)


  const getDetails = async () => {
    try {
      const response = await api.getProfile();
      if (response?.data) {
       
        setFullName(response?.data?.data.data.name || "");
        setEmail(response?.data?.data.data.email || "");
        setPhone(response?.data?.data.data.phone|| "");
      }
    } catch (err) {
      console.error("Error fetching profile details:", err);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleSaveChanges = async () => {
    const newErrors = {};

    if (!fullName.trim()) {
      newErrors.fullName = "Please fill out this field.";
    } else if (!nameRegex.test(fullName)) {
      newErrors.fullName = "Name can only contain letters and spaces.";
    }

    if (!email.trim()) {
      newErrors.email = "Please fill out this field.";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!phone.trim()) {
      newErrors.phone = "Please fill out this field.";
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
  
    handleOtpSubmit()
  };
  const updatedProfile = {
    name: fullName,
    email,
    phone,
  };

  const handleOtpSubmit = async (otp) => {


    console.log(updatedProfile);
   
    setLoading(true);

    try {
      const response = await api.editProfile(updatedProfile);
      if (response?.data.data.message === 'Update Successfull') {
        toast.success(response.data.data.message);
       nav("/My Profile")
      }
      else{
        setShowModal(true)
      }
    } catch (err) {
      console.error("Error updating profile:", err?.message);
      toast.error(err?.response?.data?.message || "Something went wrong");
    } finally {
      // Set loading to false once the API call finishes (either success or error)
      setLoading(false);
    }
  };



  return (
    <div className={styles.profilebodyContainer}>
      <div className={styles.profileheader}>
        <h2 className={styles.pheading}>Edit Profile</h2>
        <button
          className={styles.editButton}
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
      <div className={styles.profilebodyContent}>
        <div className={styles.profilebodyDetails}>
          <label htmlFor="fullName">FULL NAME</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          {errors.fullName && <p className={styles.errorMessage}>{errors.fullName}</p>}

          <label htmlFor="email">EMAIL</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setActiveField("email")}
            disabled={activeField === "phone"}
          />
          {errors.email && <p className={styles.errorMessage}>{errors.email}</p>}
          {activeField === "phone" && email && (
            <p className={styles.errorMessage}>
              You can only change phone number; email is disabled.
            </p>
          )}

          <label htmlFor="phone">PHONE NUMBER</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onFocus={() => setActiveField("phone")}
            disabled={activeField === "email"}
          />
          {errors.phone && <p className={styles.errorMessage}>{errors.phone}</p>}
          {activeField === "email" && phone && (
            <p className={styles.errorMessage}>
              You can only change email; phone number is disabled.
            </p>
          )}

          <button className={styles.savechanges} onClick={handleSaveChanges}>
          {loading ? <CircularProgress size={14} color="white" /> : "Save Changes"}
          </button>
        </div>
      </div>

      <OtpPopup
        open={showModal}
        onClose={() => setShowModal(false)}
        handleOtpSubmit={handleOtpSubmit}
        profileData={updatedProfile}
        loading={false}
        error={null}
        success={false}
      />
    </div>
  );
};

export default ProfileBody;
