import React from 'react';
import styles from './termsCondition.module.css';

const TermsCondition = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Terms & Condition</h2>
      <p className={styles.text}>
        This Privacy Policy Describes Our Policies And Procedures On The Collection, Use And Disclosure Of Your Information When You Use The Service And Tells You About Your Privacy Rights And How The Law Protects You.
      
      </p>
      <p className={styles.text}>
        We Use Your Personal Data To Provide And Improve The Service. By Using The Service, You Agree To The Collection And Use Of Information In Accordance With This Privacy Policy.
      </p>

      <h3 className={styles.heading2}>Title</h3>
      <p className={styles.text}>
        Company Owns, Operates, And Maintains The Website https://www.kingsterEdu.in/ ("Company Website" / "Platform"). The Company Offers Curated And Specially Designed Higher Education And Industry-Relevant Certification Programs Online ("Programs"). In Order To Deliver The Programs Efficiently And Make Available The Website, The Company Collects Certain Information Regarding The Client/ User. The Company Values The Trust Placed By Users, And Therefore, Company Follows The Highest Standards To Protect The Client's Personal Information.
      </p>
      <p className={styles.text}>
        This Privacy Policy ("Privacy Policy") Describes How The Company Collects, Uses, Updates, Deletes, And Shares Information About The Company's Users (Hereinafter Referred To As "User") Through Company's Platform. This Privacy Policy Shall Form A Part Of The Terms By Way Of Reference. All Capitalized Terms Not Defined In This Privacy Policy Have The Respective Meanings Outlined In Terms Of Use. This Privacy Policy Applies Only To The Part Of The Platform Provided By Kingster. The Company Is Not Responsible For The Practices Of Persons, Companies, Institutions, Or Websites That Kingster Education Does Not Control, Manage Or Employ.
      </p>
      <p className={styles.text}>
        User Shall Mean And Include Any Person/Persons Who Visit, Use, Deal With, And Transact Through The Website And Have A Guest User And Browser. In Circumstances Where The User Is Less Than 18 Years Of Age, Consent From The Legal Guardian Shall Be Required, And It Is Advised That This Policy Be Read In The Presence Of The Legal Guardian.
      </p>
      <p className={styles.text}>
        The Company Takes Care And Performs Due Diligence To Ensure Privacy Aspects Of Every Individual Who Interacts With The Company's Platform, By Design, And By Default, In Alignment With All Applicable Laws And Regulations.
      </p>

    
      <p className={styles.text}>
        Please Understand That By Using Company's Platform Or Submitting Any Personally Identifiable Information (Defined Below) To The Company, The User Consents And Agrees That Company May Collect, Use, Update, Dispose Of And Retain Company's Information (Including But Not Limited To Company's Personally Identifiable Information) Following This Privacy Policy, And As Permitted Or Required By Law. Kingster Education Reserves The Right To Share The Information Collected Hereunder With Its Affiliates. In The Event Of Sale Or Transfer Of All Or A Portion Of The Company's Business Assets, Consumer Information May Be One Of The Business Assets Transferred As Part Of The Transaction. If The User Does Not Agree With These Terms, Please Do Not Provide Any Personally Identifiable Information To Kingster. If The User Refuses Or Withdraws Company’s Consent, Or If The User Chooses Not To Provide Company With Any Required Personally Identifiable Information, Company May Not Provide User With The Services That Can Be Offered On Company's Platform.
      </p>

      <p className={styles.text}>
        This Policy Has Been Drafted In Accordance With The Applicable Data Protection Laws, Rules, And Regulations But Not Limited To General Data Protection Regulation, 2018 ("GDPR"), The Information Technology Act, 2000 ("IT Act"), And The Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Or Information) Rules, 2011 ("IT Rules"), Collectively Referred To As "Data Protection Laws".
      </p>
      <p className={styles.text}>
      Company (Kingster Education Private Limited) owns, operates, and maintains the website https://www.kingster.edu.in (“Company website” / “Platform”). The Company offers curetted and specially designed higher education and industry-relevant certification programs online (“Programs”). In order to deliver the Programs efficiently and make available the website, the Company collects certain information regarding the Client/ User. The Company values the trust placed by Users, and therefore, Company follows the highest standards to protect the Client’s Personal Information.
      </p>
      <p className={styles.text}>
      This Privacy Policy (“Privacy Policy”) describes how the Company collects, uses, updates, delete, and shares information about the Company’s users (hereinafter referred to as “User” “) through Company’s Platform. This Privacy Policy shall form a part of the Terms by way of reference. All capitalized terms not defined in this Privacy Policy have the respective meanings outlined in terms of Use. This Privacy Policy applies only to the part of the Platform provided by Kingster. The Company is not responsible for the practices of persons, companies, institutions, or websites that Kingster Education does not control, manage or employ.
      </p>
      <p className={styles.text}>
      User shall mean and include any person/persons who visit, Use, deal with, and transact through the website and have a guest user and browser. In circumstances where the User is less than 18 years of age, consent from the legal guardian shall be required, and it is advised that this Policy be read in the presence of the legal guardian.
      </p>
      <p className={styles.text}>
      The Company takes care and performs due diligence to ensure privacy aspects of every individual who interacts with the Company’s Platform, by design, and by default, in alignment with all applicable laws and regulations.
      </p>
      <p className={styles.text}>
      Please understand that by using Company’s Platform or submitting any Personally Identifiable Information (defined below) to the Company, the user consents and agrees that Company may collect, use, update, disclose and retain Company’s information (including but not limited to Company’s Personally Identifiable Information) following this Privacy Policy, and as permitted or required by law. Kingster Education reserves the right to share the information collected hereunder with its affiliates. In the event of sale or transfer of all or a portion of the Company’s business assets, consumer information may be one of the business assets transferred as part of the transaction. If the User does not agree with these Terms, please do not provide any Personally Identifiable Information to Kingster. If the User refuses or withdraws Company’s consent, or if User chooses not to provide Company with any required Personally Identifiable Information, Company may not provide User with the services that can be offered on Company’s Platform.
      </p>
      <p className={styles.text}>
      This Policy has been drafted in accordance with the applicable data protection laws, rules, and regulations but not limited to General Data Protection Regulation, 2018 (“GDPR”), the Information Technology Act, 2000 (“IT Act”), and the Information Technology (Reasonable Security Practices and Procedures And Sensitive Personal Data or Information) Rules, 2011 (“IT Rules”), collectively referred to as “Data Protection Laws.”
      </p>
    </div>
  );
};

export default TermsCondition;
