
import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNUPCfbk8faHFa7P2fx1xX5x9zPNwKPNk",
  authDomain: "missy-moppet.firebaseapp.com",
  projectId: "missy-moppet",
  storageBucket: "missy-moppet.appspot.com",
  messagingSenderId: "40070972559",
  appId: "1:40070972559:web:cdadac21f576e8f6bc9cfa"
};

 const app = initializeApp(firebaseConfig);

 export const auth = getAuth(app);
