import React from 'react';
import ProfileBody from '../../dashcomponents/profilebody/ProfileBody';
import ProfileMenu from '../../dashcomponents/profilemenu/ProfileMenu';
import styles from "./paymentcard.module.css"

const PaymentCard = () => {
    return (
        <>
            <div className={styles.myprofile}>
                <div className={styles.prodetailsContainer}>
                    <div className={styles.profileheader}>
                        <h2>Payment Cards</h2>
                        <button className={styles.editButton} /* onClick={handleEdit} */>
                            {/* {isEditing ? 'Save' : 'Edit profile'} */}
                            Add new card
                        </button>
                    </div>
                    <div className={styles.profilebodyContent}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                <span>Debit card</span>
                                <span className={styles.default}>DEFAULT</span>
                            </div>

                            <div className={styles.content}>
                                <p className={styles.name}>Dainne Russell</p>
                                <p className={styles.address}>XXXX XXXX XXXX 5678</p>
                            </div>

                            <div className={styles.orderbuttons}>
                                <button className={styles.trackOrderBtn}>Edit</button>
                                <button className={styles.trackOrderBtn}>Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default PaymentCard;
