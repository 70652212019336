import React, { useEffect, useState } from "react";
import styles from "./cartpageDashboard.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getCartProducts,
  addProductToCart,
  removeFromCart,
} from "../../redux/reducers/cartSlice";
import { useNavigate } from "react-router-dom";
import { Bin } from "../../Svg";
import { Box, CircularProgress } from "@mui/material";

import EmptyPage from "../emptyPage";

const CartPageDashbord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartData, isLoading } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCartProducts());
  }, [dispatch]);

  const getFutureDate = (daysToAdd) => {
    const today = new Date();
    today.setDate(today.getDate() + daysToAdd);

   

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return today.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
};

const futureDate = getFutureDate(5);

  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  const formatPrice = (price) => {
    return (price * rate).toFixed(2);
  };

  const incrementCount = (item) => {
    const body = { productId: item?.item?._id, count: 1, countInc: true, size: item?.size, color: item?.color };
    dispatch(addProductToCart(body));
  };

  const decrementCount = (item) => {
    const body = { productId: item?.item?._id, count: 1, size: item?.size, color: item?.color };
    dispatch(addProductToCart(body));
  };

  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  };

  const baseTotal = cartData?.reduce(
    (acc, item) => acc + (item?.item?.basePrice || 0) * (item?.count || 0),
    0
  );

  const saleTotal = cartData?.reduce(
    (acc, item) => acc + (item?.item?.salePrice || 0) * (item?.count || 0),
    0
  );

  return (
    <div className={styles.Container}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : cartData?.length > 0 ? (
        <div className={styles.cartContainer}>
          {/* Left Side - Cart Items */}
          <div className={styles.cartItems}>
            <table className={styles.cartTable}>
              <thead>
                <tr>
                  <th className={styles.heading}>Image</th>
                  <th className={styles.heading}>Product</th>
                  <th className={styles.heading}>Quantity</th>
                  <th className={styles.heading}>Price</th>
                  <th className={styles.heading}>Total</th>
                  <th className={styles.heading}></th>
                </tr>
              </thead>
              <tbody>
                {cartData?.map((item, index) => (
                  <tr key={index} className={styles.productDetailes}>
                    <td>
                      <img
                        src={item?.item?.featuredImage}
                        alt={item?.item?.name || "Product Image"}
                        className={styles.productImage}
                      />
                    </td>
                    <td>
                      <div className={styles.head}>{item?.item?.name}</div>
                      <p className={styles.variation}>{item?.color}, {item?.size}</p>
                    </td>
                    <td className={styles.head}>
                      <div className={styles.quantityControls}>
                        <div className={styles.Controls}>
                          <button
                            className={styles.opreter}
                            onClick={() => decrementCount(item)}
                          >
                            -
                          </button>
                          <span className={styles.num}>{item?.count || 1}</span>
                          <button
                            className={styles.opreter}
                            onClick={() => incrementCount(item)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className={styles.head}>{currencySymbol} {formatPrice(item?.item?.salePrice || item?.item?.basePrice)}</td>
                    <td className={styles.head}>{currencySymbol} {formatPrice((item?.item?.salePrice || item?.item?.basePrice) * (item?.count || 1))}</td>
                    <td>
                      <button
                        className={styles.removeButton}
                        onClick={() => handleRemoveItem(item)}
                      >
                        <Bin />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
           
            <div className={styles.addMoreItems} >
              <span className={styles.more} onClick={()=>navigate("/Category Products/")}>
                Add More Items
              </span>
            </div>
          </div>

          {/* Right Side - Order Summary */}
          <div className={styles.orderSummary}>
            <h2 className={styles.Order}>Order Summary</h2>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Price</span>
              <span className={styles.priceSummary}>{currencySymbol} {formatPrice(baseTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Discount</span>
              <span className={styles.priceSummary}>{currencySymbol} {formatPrice(baseTotal - saleTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Shipping</span>
              <span className={styles.FreeSummary}>Free</span>
            </div>
            <hr />
            <div className={styles.summaryTotal}>
              <span className={styles.priceSummary}>Total</span>
              <span className={styles.bold}>{currencySymbol} {formatPrice(saleTotal)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Estimated Delivery by</span>
              <span className={styles.bold}>{futureDate}</span>
            </div>
            <button
              className={styles.checkoutButton}
              onClick={() => cartData.length > 0 && navigate("/checkout")}
              disabled={cartData.length === 0}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
          {cartData?.length > 0 ?
       <div className={styles.mobilecontainer}>
<h2 className={styles.mobileheading} onClick={() => navigate('/Category Products  ')}>Add More Items</h2>
<div className={styles.mobilecontant1}>
{cartData?.map((item, index) => (
    <div className={styles.mobilecontant}>
        
<div className={styles.mobilebox1}>
<img
                        src={item?.item?.featuredImage}
                        alt={item?.item?.name || "Product Image"}
                        className={styles.productImage}
                      />
                       <div className={styles.rast}>
                     <button
                      className={styles.removeButton}
                      onClick={() => handleRemoveItem(item)}
                    >
                      <Bin />
                    </button>
                    <h5 className={styles.head}>{item?.item?.name}</h5>
                    </div>
    </div>
    <div className={styles.mobilebox}>
<p className={styles.heading}>Price</p>
<p className={styles.head}>₹{item?.item?.salePrice.toFixed(2)}</p>
  </div>
  <div className={styles.mobilebox}>
  <p className={styles.heading}>Quantity</p>
  <div className={styles.quantityControls}>
                      <div className={styles.Controls}>
                        <button
                          className={styles.opreter}
                          onClick={() => decrementCount(item)}
                        >
                          -
                        </button>
                        <span className={styles.num}>{item.count}</span>
                        <button
                          className={styles.opreter}
                          onClick={() => incrementCount(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
  </div>
  <div className={styles.mobilebox}>
<p className={styles.heading}>Total</p>
<p className={styles.head}>
                    ₹{(item?.item?.salePrice * item.count).toFixed(2)}
                  </p>
  </div>
  
        </div>
     ))}
    </div>
    <div className={styles.orderSummary}>
            <h2 className={styles.Order}>Order Summary</h2>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Price</span>
              <span className={styles.priceSummary}>Rs {baseTotal.toFixed(2)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Discount</span>
              <span className={styles.priceSummary}>Rs {(baseTotal - saleTotal).toFixed(2)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Shipping</span>
              <span className={styles.FreeSummary}>Free</span>
            </div>
            <hr />
            <div className={styles.summaryTotal}>
              <span className={styles.priceSummary}>Total</span>
              <span className={styles.bold}>Rs {saleTotal.toFixed(2)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.priceSummary}>Estimated Delivery by</span>
              <span className={styles.bold}> 01 Jun, 2024</span>
            </div>
            <button
              className={styles.checkoutButton}
              onClick={() => cartData.length > 0 && navigate("/checkout")}
              disabled={cartData.length === 0}
            >
              Proceed to Checkout
            </button>
          </div>
          
    </div>
    :null}
    </div>
  );
};

export default CartPageDashbord;
