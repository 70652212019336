import React from 'react';
import styles from './loginpopup.module.css';

const LoginPopup = ({ message, onClose }) => {
  return (
    <div className={styles.blur_background}>
      <div className={styles.popup_card}>
        <button className={styles.close_btn} onClick={onClose}>
          &times;
        </button>
        <div className={styles.icon}>
          {/* You can insert an icon or animation here */}
          <svg width="100" height="100" viewBox="0 0 100 100">
            {/* A simple success icon */}
            <circle cx="50" cy="50" r="48" stroke="green" strokeWidth="4" fill="none" />
            <path d="M30 50 L45 65 L70 40" stroke="green" strokeWidth="4" fill="none" />
          </svg>
        </div>
        <h3>{message}</h3>
      </div>
    </div>
  );
};

export default LoginPopup;
