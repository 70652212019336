import React from 'react'
import styles from "./dress.module.css"
const Dress = () => {
  return (
    <div className={styles.container}>
          <div className={styles.section}>
          <div className={styles.titles}>
            <h2 className={styles.heading}>Dress Your Little Man</h2>
            <p className={styles.text}>With Stylish And Diverse Clothing Options"</p>

          </div>
          <div className={styles.boxs}>
          <div className={styles.box1}>
      
          <img src={require('../../assets/images/lookbook51.png')} className={styles.mainImg_1} />
          </div>
          <div className={styles.box2}>
        
          <img src={require('../../assets/images/lookbook61.png')} className={styles.mainImg_1} />
          
          </div>

          </div>
          </div>

    </div>
  )
}

export default Dress