import React, { useEffect, useState } from "react";
import styles from "./categorypage.module.css";
import { Box } from "@mui/material";
import {
  Fouricon,
  Likewhite,
  LikeRed,
  Menuarrow,
  MenuSvg,
  Paginationleft,
  Paginationright,
  PriceArrow,
  PriceArrowrevers,
  Star,
} from "../../Svg";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import StarRatings from "react-star-ratings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Rectangle1 from "../../assets/images/Rectangle1.png";
import axios from "axios";
// import api from "../../utils/Api";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../redux/reducers/cartSlice";
import {
  searchOrFilterProduct,
  sortProducts,
  setFromSearch,
  subscribeToOutofstockProducts
} from "../../redux/reducers/productSlice";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
} from "../../redux/reducers/wishlistSlice";
import Popup from "../popup/Popup";
import ErrorPage from "../errorpage/ErrorPage";
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import EmptyPage from "../emptyPage";

const priceRangesINR = [
  { range: `₹100 - ₹499`, checked: false },
  { range: "₹500 - ₹999", checked: false },
  { range: "₹1000 - ₹1999", checked: false },
  { range: "₹2000 - ₹9999", checked: false },
];

const priceRangesUSD = [
  { range: `$1 - $40`, checked: false },
  { range: "$40 - $80", checked: false },
  { range: "$80 - $120", checked: false },
  { range: "$120 and $160", checked: false },
];

const Categorypage = () => {
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const nav = useNavigate();

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  const {
    productData,
    filterOptions,
    pagination,
    loading,
    error,
    isMobile,
    fromSearch,
  } = useSelector((state) => state.products);

  const { wishlistData } = useSelector((state) => state.wishlist);

  const { cartData } = useSelector((state) => state.cart);

  console.log("isMobile", isMobile);

  console.log("fromSearch", fromSearch);

  // console.log("wishlist daaata",wishlistData)

  console.log("redux products", productData);

  const handleAddToCart = (item) => {
    const body = {
      productId: item?._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    dispatch(addProductToCart(body));
  };

  const handleAddToWishList = (item) => {
    // console.log("itemmmmmmmmm", item);

    const body = {
      productId: item._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    const bodyToDelete = {
      item: item,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    if (
      wishlistData?.some((wishlistItem) => wishlistItem?.item?._id === item._id)
    ) {
      dispatch(deleteFromWishlist(bodyToDelete));
    } else {
      dispatch(addToWishlist(body));
    }
  };

  const handleSortChange = (e) => {
    const newSortValue = e.target.value;
    setSelectedSort(newSortValue); // Update state
    dispatch(sortProducts(newSortValue)); // Use the new value directly
};


  const [priceFilterOpen, setPriceFilterOpen] = useState(true);

  // const [products, setProducts] = useState([]);

  // Calculate total number of pages
  // const totalPages = Math.ceil(products.length / itemsPerPage);

  const [searchInput, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [minPriceInput, setMinPriceInput] = useState(0)
  const [maxPriceInput, setMaxpriceInput] = useState(100)
  const [selectedSort, setSelectedSort] = useState("Default Sorting")


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [imageLoading, setImageLoading] = useState(true);

  // Handle the image load event
  const handleImageLoad = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    setCurrentPage(pagination?.currentPage);
    setTotalPages(pagination?.totalPages);
    setTotalItems(pagination?.totalItems);
    setItemsPerPage(pagination?.limit || 20);
  }, [pagination]);

  // console.log("currPages", currentPage);
  // console.log("currTotalpages", totalPages);
  // console.log("currtotalItems", totalItems);
  // console.log("currItemspage", itemsPerPage);

  const calculateShowingRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, totalItems);
    return { start, end };
  };

  const { start, end } = calculateShowingRange();
  // Function to handle page changes
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(
          1,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          1,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return pages;
  };
  const pageNumbers = getPageNumbers();

  // console.log("selectedCategory", selectedCategory);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMinPriceChange = (e) => {
    const input = e.target.value.replace(/[^0-9.]/g, "");
    setMinPriceInput(input);
    // setSelectedRange(null);
  };

  const formattedMinPrice = currency === "USD" ? `$${minPriceInput}` : `₹${minPriceInput}`;

  const handleMaxPriceChange = (e) => {
    const input = e.target.value.replace(/[^0-9.]/g, "");
    setMaxpriceInput(input);
  };

  const formattedMaxPrice = currency === "USD" ? `$${maxPriceInput}` : `₹${maxPriceInput}`;

  const handlePriceRangeChange = (range) => {
    // Parse the selected range
    let [min, max] = range.range.split(" - ").map((price) => {
      const num = price.replace(/[^0-9]/g, "");
      return parseInt(num, 10);
    });
  
    console.log("minmax", min, max);
  
    if (currency === "USD") {
      min = Math.round(min / rate);
      max = Math.round(max / rate);
    }
  
    console.log("min", min);
    console.log("max", max);
  
    // If the same range is selected, deselect it
    if (selectedRange?.range === range.range) {
      setMinPrice(0); // Reset the min price
      setMaxPrice(100000); // Reset the max price
      setSelectedRange(null); // Deselect the range
    } else {
      setMinPrice(min || null); // Set new min price
      setMaxPrice(max); // Set new max price
      setSelectedRange(range); // Select the new range
    }
  };
  
  

  const handleColorChange = (color) => {
    // If the same color is selected, deselect it
    if (selectedColor === color) {
      setSelectedColor(""); // Deselect the current color
    } else {
      setSelectedColor(color); // Select a new color
    }
  };
  const handleSizeChange = (size) => {
    // If the same size is selected, deselect it
    if (selectedSize === size) {
      setSelectedSize(""); // Deselect the current size
    } else {
      setSelectedSize(size); // Select a new size
    }
  };

  const handleCategoryClick = (category) => {
    if(selectedCategory === category.name){
      setSelectedCategory("")
    }else{
      setSelectedCategory(category?.name); // Set the selected category name

    }
  };

  

  const handleSave = () => {

    
    const filters = {
      searchInput,
      minPrice:minPriceInput/rate,
      maxPrice:maxPriceInput/rate,
      selectedCategory,
      selectedColor: selectedColor,
      selectedSize: selectedSize,
      page: currentPage,
    };
      dispatch(searchOrFilterProduct(filters))
      window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (fromSearch) {
      dispatch(setFromSearch(false));
    } else {
      // if (isMobile !== true) {
        const filters = {
          searchInput,
          minPrice,
          maxPrice,
          selectedCategory,
          selectedColor: selectedColor,
          selectedSize: selectedSize,
          page: currentPage,
        };

        // Trigger the API call with filters
        dispatch(searchOrFilterProduct(filters));
        window.scrollTo(0, 0);
      // }
    }
  }, [
    searchInput,
    selectedColor,
    selectedSize,
    maxPrice,
    selectedCategory,
    currentPage,
  ]);

  const togglePriceFilter = () => {
    setPriceFilterOpen(!priceFilterOpen);
  };

  const [categoryFilterOpen, setCategoryFilterOpen] = useState(true);

  const toggleCategoryFilter = () => {
    setCategoryFilterOpen((prev) => !prev);
  };

  const [colorFilterOpen, setColorFilterOpen] = useState(true);
  const toggleColorFilter = () => {
    setColorFilterOpen((prev) => !prev);
  };
  const [sizeFilterOpen, setSizeFilterOpen] = useState(true);

  const toggleSizeFilter = () => {
    setSizeFilterOpen((prev) => !prev);
  };

  // Get the current items to display
  // const currentItems = products.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  // Handle page navigation
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <div className={styles.search}>
          <h3 className={styles.searchTitle}>Search</h3>
          <input
            type="text"
            placeholder="Search products..."
            className={styles.searchInput}
            value={searchInput}
            onChange={handleSearchChange}
          />
        </div>
        <div className={styles.filterSection}>
          <h3 className={styles.catagoryTitle} onClick={toggleCategoryFilter}>
            Filter by Categories
            {categoryFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} />
            ) : (
              <PriceArrow className={styles.arrowDown} />
            )}
          </h3>
          {categoryFilterOpen && (
            <ul className={styles.filterList}>
              {filterOptions?.category?.map((category, index) => (
                <li
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  className={`${styles.categoryItem} ${
                    selectedCategory === category?.name ? styles.active : ""
                  }`}
                >
                  {category?.name} <span>{category?.count}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={styles.filterSection}>
          <h3 className={styles.priceTitle} onClick={togglePriceFilter}>
            Filter by Price
            {priceFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} />
            ) : (
              <PriceArrow className={styles.arrowDown} />
            )}
          </h3>
          <div>
            {priceFilterOpen && (
              <div className={styles.priceFilterContent}>
                  <div className={styles.priceRange}>
                    {/* Custom input for min and max price */}
                    <input
                      type="text"
                      placeholder={currency === "USD" ? `$100.00` : `₹100`}  
                      value={formattedMinPrice}
                      onChange={handleMinPriceChange}
                    />
                    <span>
                      {" "}
                      <hr className={styles.line}></hr>{" "}
                    </span>
                    <input
                      type="text"
                      placeholder={currency === "USD" ? `$750.00` : `₹750`}                      
                      value={formattedMaxPrice}
                      onChange={handleMaxPriceChange}
                    />
                  </div>
                {/* Save button */}
                <button className={styles.saveButton} onClick={handleSave}>
                  Save
                </button>
                <ul className={styles.priceList}>
                  {(currency === "USD" ? priceRangesUSD : priceRangesINR).map(
                    (priceRange, index) => (
                      <li key={index}>
                        <input
                          type="checkbox"
                          checked={selectedRange === priceRange}
                          onChange={() =>
                            handlePriceRangeChange(priceRange, currency)
                          } // Pass currency to the function
                        />
                        {priceRange.range}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={styles.filterSection}>
          <h2 className={styles.colorTitle} onClick={toggleColorFilter}>
            {" "}
            Filter by Color
            {colorFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} /> // Replace with your actual arrow icon or component
            ) : (
              <PriceArrow className={styles.arrowDown} /> // Replace with your actual arrow icon or component
            )}
          </h2>

          {colorFilterOpen && (
            <ul className={styles.colorList}>
              {filterOptions?.color?.map((color, index) => {
                const colorCode = chroma(color.color).hex();
                return (
                  <li
                    key={index}
                    className={`${styles.colorItem} ${styles.categoryItem} ${
                      selectedColor === color.color ? styles.active : ""
                    }`}
                    onClick={() => handleColorChange(color.color)}
                  >
                    <span className={styles.frist}>
                      <span
                        className={styles.colorCircle}
                        style={{
                          backgroundColor: colorCode,
                        }}
                      ></span>
                      <p className={styles.text}>{color.color}</p>
                    </span>
                    <p className={styles.text55}>{color.count}</p>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className={styles.filterSection}>
          <h2 className={styles.sizeTitle} onClick={toggleSizeFilter}>
            {" "}
            Filter by Size
            {sizeFilterOpen ? (
              <PriceArrowrevers className={styles.arrowUp} /> // Replace with your actual arrow icon or component
            ) : (
              <PriceArrow className={styles.arrowDown} /> // Replace with your actual arrow icon or component
            )}
          </h2>
          {sizeFilterOpen && (
            <ul className={styles.sizeList}>
              {filterOptions?.size?.map((size, index) => (
                <li key={index} className={styles.sizeItem}>
                  <span>
                    <input
                      type="checkbox"
                      checked={selectedSize.includes(size.size)}
                      onChange={() => handleSizeChange(size.size)}
                    />{" "}
                    {size.size}
                  </span>
                  <span className={styles.count}>{size.count}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* section2 right side product */}

      <div className={styles.section2}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 20,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {productData?.length > 0 ? (
              <>
                <div className={styles.box2}>
                  {/* <div className={styles.menubars}>
                    <div className={styles.fouricon}>
                      <Fouricon />
                    </div>
                    <div className={styles.fouricon}>
                      <MenuSvg />
                    </div>
                  </div> */}
                 
                  <div className={styles.showing}>
                    <h5 className={styles.show12}>
                      Showing {start}–{end} of {totalItems} results
                    </h5>
                  </div>
                  <div className={styles.showing}>
                    <div className={styles.dropdownContainer}>
                      <select
                        className={styles.selectBox}
                        onChange={handleSortChange}
                      >
                        
                        <option hidden >{selectedSort}</option>
                        <option value="Default Sorting">
                        Default Sorting
                        </option>
                        <option value="priceLowToHigh">
                          Price: Low to High
                        </option>
                        <option value="priceHighToLow">
                          Price: High to Low
                        </option>
                        <option value="newest">Newest</option>
                      </select>
                      <div className={styles.icon}>
                        <Menuarrow />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.productcount}>
                  <span className={styles.show124}>
                  Showing {start}–{end} of {totalItems} results                  </span>
                  </div>
                <div className={styles.bag}>
                  {productData?.map((item, index) => {
                    // Adjust prices if currency is USD
                    const displaySalePrice =
                      currency === "USD"
                        ? item.salePrice * rate
                        : item.salePrice;
                    const displayBasePrice =
                      currency === "USD"
                        ? item.basePrice * rate
                        : item.basePrice;
                    const currencySymbol = currency === "USD" ? "$" : "₹";

                    return (
                      <Link
                        to={`/Category Products/Product details/${item._id}`}
                        style={{ textDecoration: "none" }}
                        key={index}
                      >
                        <div className={styles.box1} key={index}>
                          <div className={styles.maincontainer}>
                            
                            <img
                              // onLoad={handleImageLoad}
                              loading="lazy" 
                              src={item?.featuredImage}
                              className={styles.mainImg_1}
                              style={{ display: loading ? 'none' : 'block' }}
                            />
                            <div
                              style={{ position: "absolute", top: 1, left: -4 }}
                            >
                              <p className={styles.sale}>SALE</p>
                            </div>
                          </div>
                          <div className={styles.content}>
                            <div className={styles.left}>
                              <h4 className={styles.name}>{item.name}</h4>
                              <div className={styles.stars}>
                                <StarRatings
                                  rating={item?.averageRating}
                                  starRatedColor="gold"
                                  numberOfStars={5}
                                  starDimension="15px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                              {item?.stockQuantity > 0 ? (
                              <div className={styles.price}>
                                <h5 className={styles.discoutprice}>
                                  {currencySymbol}
                                  {displaySalePrice.toFixed(2)}
                                </h5>
                                <h5 className={styles.beforeprice}>
                                  {currencySymbol}
                                  {displayBasePrice.toFixed(2)}
                                </h5>
                              </div>
                              ) : (
                                <div
                                
                                style={{ color: "red", marginBottom:'8%',fontSize:'14px' }}
                              >
                                Out of stock
                             
                              </div>
                              )}
                            </div>
                            <div
                              className={styles.right}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                
                              }}
                            >
                              <div
                                className={styles.Like}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (token) {
                                    handleAddToWishList(item);
                                  } else {
                                    nav("/login");
                                    toast.warning("Please login");
                                  }
                                }}
                              >
                                {wishlistData.some(
                                  (wishlistItem) =>
                                    wishlistItem?.item?._id === item._id
                                ) ? (
                                  <FavoriteIcon
                                    style={{
                                      width: "25px",
                                      color: "red",
                                      border: "none",
                                    }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon
                                    sx={{
                                      stroke: "#ffffff",
                                      strokeWidth: 1,
                                      width: "25px",
                                      color: "black",
                                    }}
                                  />
                                )}
                              </div>
                              {item?.stockQuantity < 1 ? (
                                <>
                              
                                <div  className={styles.AddCart} onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  dispatch(subscribeToOutofstockProducts({productId:item?._id}))
                                }
                                }>+ Notify me</div>
                                </>
                              ) : (
                                <>
                                  {cartData?.some(
                                    (cartItem) =>
                                      cartItem?.item?._id === item._id
                                  ) ? (
                                    <button
                                      className={styles.AddCart}
                                      disabled
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "not-allowed",
                                        color: "green",
                                      }}
                                    >
                                      Added to Cart
                                    </button>
                                  ) : (
                                    <>
                                      <Link to="/mycart">
                                        <button
                                          className={styles.AddCart}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (token) {
                                              handleAddToCart(item);
                                            } else {
                                              nav("/login");
                                              toast.warning("Please Login");
                                            }
                                          }}
                                          style={{
                                            background: "none",
                                            border: "none",
                                            cursor: "pointer",
                                          }}
                                        >
                                          + Add to Cart
                                        </button>
                                      </Link>
                                      {item?.stockQuantity <= 5 ? (
                                        <div
                                          style={{
                                            color: "orange",
                                            fontWeight: 400,
                                            fontSize: 12,
                                            textDecoration: "none",
                                          }}
                                        >
                                          Only {item?.stockQuantity} left in
                                          stock
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
                <div className={styles.pagination}>
                  <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={styles.paginationButton1}
                  >
                    <Paginationleft />
                  </button>

                  {pageNumbers.map((number) => (
                    <button
                      onClick={() => goToPage(number)}
                      className={`${styles.paginationButton} ${
                        number === currentPage ? styles.active : ""
                      }`}
                    >
                      {number}
                    </button>
                  ))}

                  <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={styles.paginationButton1}
                  >
                    <Paginationright />
                  </button>
                </div>
              </>
            ) : (
              <>
                <EmptyPage button={"REFRESH"} />
              </>
            )}
          </>
        )}

        <div className={styles.mobilefilter}>
          <button className={styles.Sort}>Sort</button>
        
            <button className={styles.Filter} onClick={() => {nav("/mobile-filter")
               window.scroll(0,0)}}>Filter</button>
        
        </div>
      </div>
    </div>
  );
};

export default Categorypage;
