import React, { useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import styles from "../auths/otp/otp.module.css";
import { updateProfileDetails } from "../redux/reducers/userSlice";
import { useDispatch } from "react-redux";
import toast from "../utils/toast";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import api from "../utils/Api";

const OtpPopup = ({ open, onClose, loading, error, success, profileData }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [countdown, setCountdown] = useState(5);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const nav = useNavigate();

  // Reset OTP and countdown timer when modal opens
  useEffect(() => {
    if (open) {
      setOtp(["", "", "", "", "", ""]);
      setCountdown(5); // Reset countdown timer to 5 seconds for testing
      setIsResendDisabled(true);
    }
  }, [open]);

  // Countdown logic
  useEffect(() => {
    if (open && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      // Clear interval when countdown ends
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      setIsResendDisabled(false); // Enable resend option when countdown reaches zero
    }
  }, [countdown, open]);

  // Resend OTP function
  const handleResendOtp = async () => {
    if (!isResendDisabled) {
      setCountdown(5); // Reset countdown timer to 5 seconds for testing
      setIsResendDisabled(true); // Disable the resend button immediately after clicking

      try {
        const response = await api.editProfile(profileData);
        toast.success(response.data.data.message);
      } catch (err) {
        console.error("Error updating profile:", err?.message);
        toast.error(err?.response?.data?.message || "Something went wrong");
      }
    }
  };

  // Handle OTP input
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Submit OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join(""); // Join the OTP digits into a single string

    if (otpValue.length === 6) {
      try {
        const resultAction = await dispatch(updateProfileDetails({ OTP: otpValue, ...profileData }));
        unwrapResult(resultAction);
        nav("/My Profile");
      } catch (error) {
        if (error?.message?.includes("OTP got expired")) {
          toast.error("The OTP has expired. Please request a new one.");
          setIsResendDisabled(false); 
        } else {
          toast.error("Invalid OTP or something went wrong.");
        }
      }
    } else {
      toast.error("Invalid OTP. Please enter a 6-digit OTP.");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: "none",
          borderRadius: "8px",
        }}
      >
        <div className={styles.login_container}>
          <div className={styles.form_section} >
            <div className={styles.form_container} >
              <div className={styles.number_boxes}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    className={styles.box}
                    id={`otp-input-${index}`}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
               <div  style={{ display: 'flex', flexDirection: "row" , justifyContent:'space-between',width:"200%" ,gap:"10%" }}>
              <button type="submit" onClick={handleSubmit}>
                {"Submit OTP"}
              </button>
              <button onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              </div>

              {error && <p className={styles.error}>{error}</p>}
              {success && <p className={styles.success}>OTP Verified!</p>}

              {/* <div className={styles.newuser}>
                {isResendDisabled ? (
                  <p>Resend OTP in {formatTime(countdown)}</p>
                ) : (
                  
                  <span className={styles.newuser}>
                    <br/>
                    Didn't receive OTP?{" "}
                    <span className={styles.signup} onClick={handleResendOtp}>
                      Resend
                    </span>
                  </span>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default OtpPopup;
