import React from 'react';
import styles from './cardbody.module.css';

const CardBody = () => {
    return (
        <div className={styles.profilebodyContainer}>
            <div className={styles.profileheader}>
                <h2>Add New Card</h2>
                <button className={styles.editButton} /* onClick={handleEdit} */>
                    {/* {isEditing ? 'Save' : 'Edit profile'} */}
                    Back to List
                </button>
            </div>
            <div className={styles.profilebodyContent}>

                <form className={styles.profileform} /* onSubmit={handleSubmit} */>
                  
                    <div className={styles.profileformGroup}>
                        <label>Card Holder name *</label>
                        <input
                            type="text"
                            name="holdername"
                          /*   value={formData.phone}
                            onChange={handleChange} */
                            placeholder="Holder name"
                            required
                        />
                    </div>

                    <div className={styles.profileformGroup}>
                        <label>Card number ( 16 digits)</label>
                        <input
                            type="text"
                            name="cardNumber"
                          /*   value={formData.houseNumber}
                            onChange={handleChange} */
                            placeholder="Card number"
                        />
                    </div>
            

                    <div className={styles.profilerow}>
                        <div className={styles.profileformGroup}>
                            <label>Expiry date*</label>
                            <input
                                type="text"
                                name="date"
                               /*  value={formData.city}
                                onChange={handleChange} */
                                placeholder="DD/YY"
                                required
                            />
                        </div>

                        <div className={styles.profileformGroup}>
                            <label>CVV</label>
                            <input
                                type="text"
                                name="cvv"
                               /*  value={formData.zip}
                                onChange={handleChange} */
                                placeholder="---"
                                required
                            />
                        </div>
                    </div>


                    <div className={styles.buttonGroup}>
                        <button type="button" className={styles.cancelButton}>
                            Cancel
                        </button>
                        <button type="submit" className={styles.saveButton}>
                            Save
                        </button>
                    </div>
                </form>

              


            </div>
        </div>
    );
};

export default CardBody;
