import React, { useState } from "react";
import styles from "./returnexchange.module.css";
import ReviewPopup from "../../../smallcomponents/reviewpopup/ReviewPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { exchangeRequest } from "../../../redux/reducers/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../utils/Api";
import { Button } from "@mui/material";
import ProfileMenu from "../../dashcomponents/profilemenu/ProfileMenu";
import { useParams } from "react-router-dom";
// import { CrossIcon,Closeicon } from "../../../Svg";


const ReturnExchange = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {id} = useParams()

  const { orderToExchange: orderData, selectedItemsToExchange: selectedItems } =
    useSelector((state) => state.order);

  console.log(orderData);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [isProductUnused, setIsProductUnused] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleClose = () => {
    setIsPopupVisible(false);
  };

  console.log("uploadedImages",uploadedImages)

  const MAX_FILE_SIZE = 20 * 1024 * 1024; 

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const validImages = [];

    files.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        validImages.push(URL.createObjectURL(file));
      } else {
        alert(`File ${file.name} is too large. Maximum file size is 20 MB.`);
      }
    });

    setUploadedImages(prevImages => [...prevImages, ...validImages]);
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };


  // Get rate and currency from localStorage
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const currency = localStorage.getItem("currency") || "INR";
  const currencySymbol = currency === "USD" ? "$" : "₹";

  // Function to convert price based on currency
  const convertPrice = (price) => {
    return (price * rate).toFixed(2);
  };

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsProductUnused(event.target.checked);
  };

  const handleSubmit = async () => {
    if (!selectedReason) {
      alert("Please select a reason for exchange.");
      return;
    }

    if (!isProductUnused) {
      alert(
        "Please confirm that the product is unused with original tags intact."
      );
      return;
    }

    const formData = new FormData();
    formData.append("reason", selectedReason);
    selectedItems.forEach((item) =>
      formData.append("products", item.productId._id)
    );
    uploadedImages.forEach((imageUrl) => formData.append("images", imageUrl));

    try {
      dispatch(exchangeRequest({ orderId: orderData?._id, formData }));
    } catch (error) {
      console.error("Error submitting exchange request", error);
      alert("An error occurred while submitting the exchange request.");
    }
  };

  return (
    <div style={{display:"flex"}}>
      <ProfileMenu selected={"Order History"}/>
      <div className={styles.myprofile}>
        <div className={styles.ordetailsContainer}>
          <div className={styles.ordetailsheader}>
            <div className={styles.ordetails_status}>
              <h2>Return for exchange</h2>
              <div className={styles.status}>
                Eligible to exchange till May 10, 2024
              </div>
            </div>
            <button className={styles.editButton} onClick={() => navigate(`/Order Details/${id}`)}>Back to order</button>
          </div>
          <div className={styles.ordetailsbody}>
            <div className={styles.orderSummary}>
              <table className={styles.ordertable}>
                <thead>
                  <tr>
                  
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItems?.map((product, index) => (
                    <tr key={index}>
                      <td className={styles.productInfo}>
                        <div className={styles.productalign}>
                          <img
                            width={50}
                            src={product?.productId?.featuredImage}
                            alt={product.name}
                            className={styles.productImage}
                          />
                          <div className={styles.pnamereview}>
                            <span>{product.name}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {currencySymbol}
                        {convertPrice(product?.productId?.salePrice)}
                      </td>
                      <td>x{product?.units}</td>
                      <td>
                        {currencySymbol}
                        {convertPrice(
                          product?.productId?.salePrice * product?.units
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.pnamereview}>
                <span>No refund available</span>
                <button
                  className={styles.reviewbtn}
                  onClick={() => navigate("/privacypolicy")}
                >
                  View Policy
                </button>
              </div>
            </div>

            <div className={styles.order_rejection}>
              <div className={styles.rejection}>
                <h4>Reason for exchange?</h4>
                <p>
                  Please choose the correct reason for exchange. This
                  information is used to improve our service.
                </p>
                <hr />
                <div className={styles.rejection_inputs}>
                  <label>
                    <input
                      type="radio"
                      value="wrong_defective"
                      checked={selectedReason === "wrong_defective"}
                      onChange={handleReasonChange}
                    />
                    Received a wrong or defective product
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="image_mismatch"
                      checked={selectedReason === "image_mismatch"}
                      onChange={handleReasonChange}
                    />
                    Image shown did not match the actual product
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="quality_issues"
                      checked={selectedReason === "quality_issues"}
                      onChange={handleReasonChange}
                    />
                    Quality Issues
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="size_fit"
                      checked={selectedReason === "size_fit"}
                      onChange={handleReasonChange}
                    />
                    Size or fit issues
                  </label>
                </div>
                <hr />

                <div style={{   flexDirection: "column",  }}>
      <p>Upload photo</p>
      <input
        type="file"
        accept="image/*"
        multiple
        id="featuredFile"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <label htmlFor="featuredFile" className={styles.uploadBox}>
        <p style={{  background: "#007BFF", borderRadius: 5, color: "#fff", cursor: "pointer" }}>Upload file</p>
      </label>
      <p className={styles.sizefile}>File size not exceeds 2MB in size and supports JPG, JPEG or PNG</p>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {uploadedImages.map((imageUrl, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <img
              src={imageUrl}
              alt={`Uploaded Preview ${index + 1}`}
              style={{ maxWidth: '100%', marginTop: '10px' }}
              width={60}
              height={60}
            />
            <div onClick={() => removeImage(index)} style={{ position: 'absolute', top: -3, right: 0, cursor: 'pointer',zIndex:10 }}>
            <CrossIcon   />

            </div>
          </div>
        ))}
      </div>
    </div>

                <hr />
                <div className={styles.rejection_inputs}>
                  <label>
                    <input
                      type="checkbox"
                      checked={isProductUnused}
                      onChange={handleCheckboxChange}
                    />
                    I confirm that the product is unused with the original tags
                    intact.
                  </label>
                </div>
              </div>

              <div className={styles.orderbuttons}>
                <div>
                  <p>
                    Delivery partner will pick up the product in the next 24
                    hours
                  </p>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <button className={styles.trackOrderBtn} onClick={() => navigate(`/Order Details/${id}`)}>Cancel</button>
                  <button className={styles.invoiceBtn} onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && <ReviewPopup onClose={handleClose} />}
    </div>
  );
};

export const CrossIcon = () => {
  return (
      <svg width="10" height="10" viewBox="0 0 8 9" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.90251 0.597595C7.84 0.535105 7.75523 0.5 7.66684 0.5C7.57845 0.5 7.49368 0.535105 7.43117 0.597595L4.00017 4.0286L0.569173 0.597595C0.506664 0.535105 0.421894 0.5 0.333506 0.5C0.245118 0.5 0.160349 0.535105 0.0978396 0.597595C0.0353493 0.660105 0.000244141 0.744874 0.000244141 0.833262C0.000244141 0.92165 0.0353493 1.00642 0.0978396 1.06893L3.52884 4.49993L0.0978396 7.93093C0.0353493 7.99344 0.000244141 8.07821 0.000244141 8.1666C0.000244141 8.25498 0.0353493 8.33975 0.0978396 8.40226C0.160349 8.46475 0.245118 8.49986 0.333506 8.49986C0.421894 8.49986 0.506664 8.46475 0.569173 8.40226L4.00017 4.97126L7.43117 8.40226C7.49368 8.46475 7.57845 8.49986 7.66684 8.49986C7.75523 8.49986 7.84 8.46475 7.90251 8.40226C7.965 8.33975 8.0001 8.25498 8.0001 8.1666C8.0001 8.07821 7.965 7.99344 7.90251 7.93093L4.47151 4.49993L7.90251 1.06893C7.965 1.00642 8.0001 0.92165 8.0001 0.833262C8.0001 0.744874 7.965 0.660105 7.90251 0.597595Z" fill="red" />
      </svg>
  )
}

export default ReturnExchange;
